//import logo from './logo.svg';
import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import RouterContext from "./context/RouterContext";
import Questionario from "./components/Questionario";
import Init from "./components/Init";
import MyAdmin from "./admin/MyAdmin";
import './App.css';


const App = () => {
   
  const [pageRouter, setPageRouter] = useState({
        router: "",
        homecolor: "",
        settingcolor: "",
        homebordercolor: "",
        settingbordercolor: "",
        hometitlecolor: "",
        settingtitlecolor: "",        
    });

  return (
    <BrowserRouter>
      <RouterContext.Provider value={{ pageRouter, setPageRouter }}>
        <div className="App">
          <Routes>  
            <Route path="/survey/:idEnte" element={< Questionario />} />
            <Route path="/survey/:idEnte/:idOratorio" element={< Questionario />} />
            {/*
            <Route path="/" element={<Init />}/>
            <Route path="*" element={<MyAdmin />}/>
            <Route path="*" element={<Init />}/>
            */}

            <Route path="*" element={<MyAdmin />}/>
          </Routes>
        </div>
      </RouterContext.Provider>
    </BrowserRouter>
  );
}

export default App;
