//import "survey-core/defaultV2.min.css";
import 'survey-core/modern.min.css';
import {ThreeDots} from 'react-loader-spinner';
import React, { useEffect } from "react";
import { useCallback } from 'react';
import { useParams, } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Model } from 'survey-core';
import {properties} from "../utils/properties";
import { Survey, SurveyTimerPanel } from 'survey-react-ui';
import { surveyLocalization } from "survey-core";
import "survey-core/survey.i18n";
import { province, birthplace} from "./LBSbirthplace";
import { prefissiTel, prefissiVia } from "./datautils";

var CodiceFiscale = require('codice-fiscale-js');

const engLocale = surveyLocalization.locales["it"];

export default function Questionario() {
    
    const navigate = useNavigate();
    //const isMobile = window.innerWidth <= 600;
    const [isMobile, setIsMobile]= React.useState(false); 
    //let isMobile=false;
    // per usarlo : navigate('/home');
    //N.B. in react-router-dom 6 use history è stato sostituito da useNavigate.
    //const [loading, setLoading]= React.useState(true); 
    const [loading, setLoading]= React.useState(false); 
    //const [surveyJson2, setSurveyJson2]=React.useState("");
    let {idEnte } = useParams();
    //let {idOratorio } = useParams();
    let {naming } = "";
    //let {dataOra}="18.00 del 12/12/2023";
    const [survey1, setSurvey1] = React.useState(new Model());
    const [modelLoaded, setModelLoaded] = React.useState(false);
    

    function handleWindowSizeChange() {
      if(window.innerWidth< 600){              
        setIsMobile(true);
      }
      else      
        setIsMobile(false);      
    }

    const alertResults = useCallback((sender) => {
      console.log("DEBUG",survey1);
      const results = JSON.stringify(sender.data);
      doPostSubmitQuestionarario(results);
      //alert(results);
  });

  if(!survey1.onComplete.isEmpty){
    //survey1.onComplete.remove(alertResults)
    survey1.onComplete.clear();
    console.log("removed");
  }
  survey1.onComplete.add(alertResults);
  survey1.locale="it";
  //console.log(survey1.getQuestionByName("provinciaFiglio").choices);
  //survey1.getQuestionByName("provinciaFiglio").choices.push(province());
  
////
survey1.getAllQuestions().forEach(q => { //al caricamento della struttuta popolo i nenu' dropdpwn
  if(q.getValueName() === "Prefisso"){
    q.choices = prefissiTel;
  } else if(q.getValueName() === "AddressType"){
    q.choices = prefissiVia;
  }else if(q.getValueName() === "provinciaFiglio"){
    q.choices = province();
  }
  else{
    return;
  }  
}); 

survey1.onCurrentPageChanged.add((sender, options) => {
  window.scrollTo(0, 0);
});
/*
survey1.onValueChanged.add(function(sender, options) {

  if(options.name ==="statoNascitaFiglio"){

    if(options.value === 'Italia'){
      sender.getQuestionByName("provinciaNascitaFiglio").choices = province();
    } else{
      sender.getQuestionByName("provinciaNascitaFiglio").choices =["Estero"];
      //sender.getQuestionByName("provinciaNascitaFiglio").value = "Estero";
      sender.getQuestionByName("cittaNascitaFiglio")
    }
    
  } else if (options.name ==="provinciaNascitaFiglio"){
    var places = birthplace (options.value);
    var result = [];
    for(var i in places)
      result.push(places[i][1]);
      //sender.getQuestionByName("cittaNascitaFiglio").value = result[0];//verificare NON funziona!!!!
      sender.getQuestionByName("cittaNascitaFiglio").choices = result;

  } else if (options.name ==="provinciaFiglio"){
    var places = birthplace (options.value);
    var result = [];
    for(var i in places)
      result.push(places[i][1]);
      //sender.getQuestionByName("cittaNascitaFiglio").value = result[0];//verificare NON funziona!!!!
      sender.getQuestionByName("cittaResidenzaFiglio").choices = result;

  }
  else{
    return;
  }

});
*/

survey1.onValueChanged.add(function(sender, options) {

  if(options.name ==="statoNascitaFiglio"){
    if(options.value === 'Italia'){
      sender.getQuestionByName("provinciaNascitaFiglio").choices = province();
    } else{
      sender.getQuestionByName("provinciaNascitaFiglio").choices =["Estero"];
      //sender.getQuestionByName("provinciaNascitaFiglio").value = "Estero";
      sender.getQuestionByName("cittaNascitaFiglio")
    }

  } else if (options.name ==="provinciaNascitaFiglio"){
    var places = birthplace (options.value);
    var result = [];
    for(var i in places)
      result.push(places[i][1]);
      //sender.getQuestionByName("cittaNascitaFiglio").value = result[0];//verificare NON funziona!!!!
      sender.getQuestionByName("cittaNascitaFiglio").choices = result;

  }else if (options.name ==="provinciaFiglio"){
    var places = birthplace (options.value);
    var result = [];
    for(var i in places)
      result.push(places[i][1]);
      sender.getQuestionByName("cittaResidenzaFiglio").choices = result;

  }else {
    return;
  }
});


survey1.onValidateQuestion.add((survey1, options) => {

  if (options.name === "codiceFiscaleFiglio") {
    console.log("-------survey1.onValidateQuestion CodiceFiscale");
    const cfins = options.value;
    if (!cfins)
      return;

      var cfCheck = "";
      try {
        cfCheck = new CodiceFiscale(cfins);
        console.log("verifca CF to CF",cfCheck.toJSON()); //cfCheck.cf
      }
      catch(err) {
        console.log("errorer nel cs");
        options.error = "Inserire un Codice Fiscale valido";
        return;
      }
      if(survey1.getQuestionByValueName("CognomeFiglio").isAnswered &&
        survey1.getQuestionByValueName("NomeFiglio").isAnswered &&
        survey1.getQuestionByValueName("sessoFiglio").isAnswered &&
        survey1.getQuestionByValueName("dataNascitaFiglio").isAnswered &&
        survey1.getQuestionByValueName("cittaNascitaFiglio").isAnswered &&
        survey1.getQuestionByValueName("statoNascitaFiglio").isAnswered
      ){

        var surname_ = survey1.getQuestionByValueName("CognomeFiglio").value;
        var name_ = survey1.getQuestionByValueName("NomeFiglio").value;
        var gender_ = survey1.getQuestionByValueName("sessoFiglio").value;
        var birthdate_ = survey1.getQuestionByValueName("dataNascitaFiglio").value;
        var birthplace_ = survey1.getQuestionByValueName("cittaNascitaFiglio").value;
        const d = new Date(birthdate_);
        //let year_ = d.getUTCFullYear();
        let year_ = d.getFullYear();
        
        //let month_ = d.getUTCMonth()+1;
        //let day_ = d.getUTCDate();
        let month_ = d.getMonth()+1;
        let day_ = d.getDate();

        var cfBuilder = "";
        if(survey1.getQuestionByValueName("statoNascitaFiglio").value==='Italia'){
          console.log("italia");
          cfBuilder = new CodiceFiscale({ 
            name: name_,
            surname: surname_,
            gender: gender_,
            day: day_,
            month: month_,
            year: year_,
            birthplace: birthplace_
           });

        }else{
          console.log("estero");
          cfBuilder = new CodiceFiscale({ 
            name: name_,
            surname: surname_,
            gender: gender_,
            day: day_,
            month: month_,
            year: year_,
            birthplace: birthplace_,
            birthplaceProvincia: "EE"
           });
        }

      /*  var cfBuilder = new CodiceFiscale({ 
          name: name_,
          surname: surname_,
          gender: gender_,
          day: day_,
          month: month_,
          year: year_,
          birthplace: birthplace_
         });*/
         console.log("verifca fm to CF",cfBuilder.toJSON()); //cfBuilder.code)
    

         if(cfCheck.cf !==cfBuilder.code){
          options.error = "I dati inseriti non corrispndono con il Codice Fiscale."; 
         }
      }
  }
});

  ///

  const doPostToGetList =  function() {
    console.log("doing sedute post to get list");
        var xhr2 = new XMLHttpRequest();                
        //xhr2.open('GET', properties.productionUrl+'/rest/getSedutaReceived?idf='+meetingId,true);
        xhr2.open('GET', properties.productionUrl+'/rest/getQuestionario',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
          if (xhr2.readyState === 4) {
            if (xhr2.status === 200) {                              
                //console.log("OK192",xhr2);
                var responseText = xhr2.responseText;                    
                var obj=JSON.parse(responseText);                
                console.log("OK93",obj);      
                survey1.onComplete.clear();
                //survey1.onComplete.add(alertResults);
                //console.log("OK11",survey1);     
                //setSurvey1(new Model(json));
                setSurvey1(new Model(obj));
                survey1.locale="it";
                //survey1.onComplete.clear();                
                //survey1.getQuestionByName("provinciaFiglio").choices= province();
                survey1.onComplete.add(alertResults);
                setModelLoaded(true);     
                //console.log("PF",survey1.getQuestionByName("provinciaFiglio"));            
            } 
            else {
              //console.error("Error 1", xhr.statusText);          
              console.log("Errore recuperando il questionario");              
            }
          }
        };
        xhr2.onerror = function (e) {
            console.error("Error 122", xhr2.statusText);
          };
          
          let urlEncodedData = null;    
          xhr2.send(urlEncodedData);
      }


        const doPostSubmitQuestionarario = function(jsonData) {          
              var xhr2 = new XMLHttpRequest();                
              //xhr2.open('GET', properties.productionUrl+'/rest/postQuestionario,true);
              xhr2.open('POST', properties.productionUrl+'/rest/postQuestionario',true);
              xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
              xhr2.onload = function (e) {      
                if (xhr2.readyState === 4) {
                  if (xhr2.status === 200) {                              
                      console.log("OK192",xhr2);
                      var responseText = xhr2.responseText;                    
                      //var obj=JSON.parse(responseText);
                      console.log("OK193",responseText);    
                                               
                  } 
                  else {
                    //console.error("Error 1", xhr.statusText);     
                    survey1.showCompletedPage=false;    
                    survey1.hasErrors= true;
                    survey1.completedHtml="Errore inviando i risultati del questionario."
                    survey1.showCompletedPage=true;    
                    console.log("Errore recuperando il questionario 2");
                    
                  }
                }
              };
              xhr2.onerror = function (e) {
                  console.error("Error 122", xhr2.statusText);
                };
                
                let urlEncodedData = null;    
                xhr2.send(jsonData);
            }
    
            
    useEffect(() => {
        console.log("useEffect");
        //console.log("idEnte:", idEnte);
        //console.log("idOratorio:", idOratorio);
        window.addEventListener('resize', handleWindowSizeChange);
        //if(idOratorio!=undefined)
        //  naming=idEnte + " - "+idOratorio
        //else
        //  naming=idEnte
        //console.log("naming",naming);
        doPostToGetList();        
        
        //survey1.getQuestionByName("provinciaFiglio").choices.push(province());
    },[]);

    return (
        <div key={uuidv4()}> 
        {!modelLoaded ? (
        <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
            <ThreeDots color="#30720f" height='350' width='150'/>
        </div> 
        ):( 
          <>
          {isMobile ? (
            <div key={uuidv4()} style={{  display: "flex", 
            marginLeft: "auto", marginRight: "auto", textAlign:"left" }}> 
             <Survey model={survey1} />
            </div>  
            ):( 
              <div key={uuidv4()} style={{ width:"70%",   display: "flex", 
              marginLeft: "auto", marginRight: "auto", textAlign:"left" }}> 
               <Survey model={survey1} />
               </div>   
            )
          }
        </>     

        )
    }         
    </div>
  );
  
  }  