//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, SimpleList, Datagrid, TextField, EmailField, UrlField } from "react-admin";
import { Edit, Create, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput, EditButton} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { MyEmailField } from "./MyUrlField";
import { MyUrlField } from "./MyUrlField";
import { Fragment } from 'react';

const EnteNoAdminBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton />            
  </Fragment>
);

const EnteFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,  
];


export const EnteNoAdminList = () => (
  <List filters={EnteFilters}>
    {/*<Datagrid rowClick="edit">*/}
    <Datagrid bulkActionButtons={<EnteNoAdminBulkActionButtons />}> 
      <TextField source="id" />
      <TextField source="nome" />
      <TextField source="ragione_sociale" />
      <TextField source="referente" />
      <TextField source="telefono" />
      {/*<EmailField source="email" />      */}
      {/*<MyEmailField source="email" label="E-mail"/> */}
      <MyEmailField source="email_privacy" label="E-mail privacy"/>
      <MyEmailField source="email_referente" label="E-mail referente"/>
      <MyUrlField source="sito_web" label="Sito Web"/>   
      <TextField source="indirizzo" />
      <TextField source="codice_am" label="Codice Abbonamento Musei"/>
      {/*<TextField source="privacy_link" />*/}
      <MyUrlField source="privacy_link" />   
      <ReferenceField source="tipologia_id" reference="tipologia" label="Tipologia">
        <TextField source="tipo" />
      </ReferenceField>
      {/*<TextField source="parent_id " />
      <TextField source="tipologia_id" /> */}  
      <ReferenceField source="parent_id" reference="enti" label="Parent">
        <TextField source="nome" />
      </ReferenceField>      
    </Datagrid>
  </List>
);



