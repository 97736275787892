import { useRecordContext } from "react-admin";
import { Link } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { DateField} from "react-admin";

export const MyUrlField = ({ source }) => {
  const record = useRecordContext();
  return record ? (
    <Link target="_blank" href={record[source]} sx={{ textDecoration: "none" }}>
      {record[source]}
      <LaunchIcon sx={{ fontSize: 15, ml: 1 }} />
    </Link>
  ) : null;
};


export const MyEmailField = ({ source }) => {
  const record = useRecordContext();
  const ee='mailto:'+record[source];
  return record ? (
    <Link  href={`mailto:${record[source]}`} sx={{ textDecoration: "none" }}>
      {record[source]}      
    </Link>
  ) : null;
};


export const MyEmptyDateField = ({ source }) => {
  const record = useRecordContext();
  //console.log("DEF", record[source]);  
  if(record[source]=="1970-01-01")
    return <span></span>;
  else{
    //return <DateInput source={record[source]} label="Data di nascita" locales="it-IT" />;
    const event = new Date(record[source]);
    //const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //console.log("DEF2",event.toLocaleDateString('it-IT'));
    return <span>{event.toLocaleDateString('it-IT')}</span>;
  }
};

export const MyTestField = ({ source }) => {
  console.log("MTF",source);
  const record = useRecordContext();
  //console.log("DEF", record[source]);    
  return <span>{source}</span>;
  
};

/*
export const dateEmptyField= (props) => {
    const record = useRecordContext(props);
    console.log("DEF", record.scadenza_abbonamento_musei=="1970-01-01");
    if(record.scadenza_abbonamento_musei=="1970-01-01")
      return <span></span>;
    else
      return <span>{record.scadenza_abbonamento_musei}</span>    
};
*/
