import { Card, CardContent, CardMedia, Typography, CardHeader } from "@mui/material";
//import { makeStyles } from '@material-ui/core/styles';
import { properties } from "../utils/properties";

/*const useStyles = makeStyles((theme) => ({
 
  logo: {
      zIndex: 1,
      width: "100%",
      height: "inherit"
      
  },  
}));
*/

function getName() {
  let result = 'Benvenuto ';
  result=result+localStorage.getItem("username")  
  return result;
}


function getAdminRole(){
  let result=localStorage.getItem('admin');
  return result;
}

function getRole(){
  let result="UNDEFINED";
  console.log("r11",result,localStorage.getItem('ruolo'));
  if(localStorage.getItem('ruolo')!=undefined && localStorage.getItem('ruolo')!== null) {
    console.log("r22 into",localStorage.getItem('ruolo'));      
    result=localStorage.getItem('ruolo');
  }
  console.log("called 33 getrole", result);
  return result;
}

export const Dashboard = () => (
  <Card>
    <CardHeader title={getName()} style={{textAlign:"left"}}/>
    <CardMedia
        component="img"
        style={{ width:"100%", maxHeight:"300"}}
        //classes={{root: classes.logo}}
        image="../assets/CSP_AbbonamentoMusei.png"
        alt="logo"
      />
    {getAdminRole() ==1 || getAdminRole() >3 || getRole()=='USER-ENTE' ? (
    <CardContent>       
      {/* 
      <Typography variant="body1" color="text.secondary">
          Un'estate insieme va in vacanza! Tornerà nel 2024 ricca di nuove funzionalità!      
      </Typography>
      */}
      <Typography variant="body1" color="text.secondary">          
            Unestateinsieme Versione  {properties.version}
      </Typography>
    </CardContent>
    ):(
<CardContent>    
      <Typography variant="body1" color="text.secondary">
          Dal menù a sinistra è possibile accedere alle diverse sezioni.
      </Typography>
      
      <Typography variant="body1" color="text.secondary">
          Per effettuare un caricamento di dati, seleziona la voce "Carica Dati" ed utilizza il pulsante IMPORTA          
      </Typography>

      <Typography variant="body1" color="text.secondary">
          Si ricorda che il formato da utilizzare per Caricare Dati è il CSV. Ulteriori informazioni sono disponibili al seguente link (https://it.wikipedia.org/wiki/Comma-separated_values)

      </Typography>

      <Typography variant="body1" color="text.secondary">          

      </Typography>


      <Typography variant="body1" color="text.secondary">          
            Unestateinsieme Versione  {properties.version}
      </Typography>
    </CardContent>
    )}
  </Card>
);