//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, SimpleList, DatagridConfigurable, TextField, EmailField, UrlField, ReferenceField, TopToolbar, SelectColumnsButton, ExportButton } from "react-admin";
import { Edit, Create, SimpleForm, ReferenceInput, TextInput, SelectInput, EditButton,  DateField} from "react-admin";
import { Fragment } from 'react';
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { FilterButton } from "ra-ui-materialui";
import { MyEmailField } from "./MyUrlField";
import { Box } from "@mui/material";

const PrenotazioneBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton  exporter={exporter1} maxResults= {15000}/>      
      {/*<BulkDeleteButton mutationMode="pessimistic"/> */}
  </Fragment>
);

/*
const PrenotazioneFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,  
];
*/

//source  0 manuali , 1 museali , 2 partecipazione

const PrenotazioneFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,    
  <ReferenceInput source="ente_fruitore_id" reference="enti" label="Ente Fruitore" perPage={1000} filter={{tipologia_id: 2}} sort={{ field: 'nome', order: 'ASC' }}>     
    <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"/>
  </ReferenceInput>,
  <ReferenceInput source="ente_fornitore_id" reference="enti" label="Ente Fornitore" perPage={1000} filter={{tipologia_id: 1}} sort={{ field: 'nome', order: 'ASC' }}>     
    <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"/>
  </ReferenceInput>,
  <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000} sort={{ field: 'circuito', order: 'ASC' }}>
    <SelectInput sx={{textAlign: "left"}} option="circuito" optionText="circuito" />        
  </ReferenceInput>
];




const exporter1 = (records, fetchRelatedRecords) => {
  let data;
  let data1;
  let dat;
  let dataForExport;

  fetchRelatedRecords(records, 'ente_fornitore_id', 'enti').then(enti => {
    data1 = records.map(record => ({
      ...record,
      ente_fornitore: enti[record.ente_fornitore_id].nome,                
    })); 
    //console.log("I2",data1);
    //console.log("EXP3",JSON.stringify(data));
    fetchRelatedRecords(data1,'ente_fruitore_id','enti').then(enti =>{
      //console.log("I3",data1);
      data = data1.map(record => ({
      ...record,
      ente_fruitore: enti[record.ente_fruitore_id].nome,                
      })); 
      fetchRelatedRecords(data,'circuito_id','circuiti').then(circuiti =>{
        //console.log("I4",data);
        dat = data.map(record => ({
        ...record,
        circuito: circuiti[record.circuito_id].circuito,
      }));
      
      dataForExport = dat.map(record => {        
          const { inserimento, ente_fornitore_id, ente_fruitore_id,circuito_id,bambino_id,statoprenotazione_id, id, ...dataForExport } =record;
          return dataForExport;
      });
         
      //}); 

      //return jsonExport(dat, {
        //return jsonExport(dataForExport, {
        jsonExport(dataForExport, {
        headers: ['source', 'ente_fornitore','data_attivita', 'ora_inizio_attivita', "ora_fine_attivita","ente_fruitore", "circuito", "partecipanti", "fascia_eta", "eta_partecipanti", 
        "eventuali_disabilita", "descrizione", "durata_attivita", "accompagnatori", "referente", "telefono_referente", "mail_referente", 
        "ingresso",  "note"],
        }, (err, csv) => {
        downloadCSV(csv, 'prenotazioni');
        });
      })
    })
  });
};



const PrenotazioneActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />                  
      {/*<CreateButton basePath={basePath} /> */}
      <FilterButton/>       
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter1}
        maxResults= {15000}
      />    
    </TopToolbar>
  );
};





export const PrenotazioniList = (props) => (
  <List {...props} sort={{ field: 'data_attivita', order: 'ASC' }}  filters={PrenotazioneFilters} perPage={50} actions={<PrenotazioneActions />}>
    {/*<Datagrid rowClick="edit">  */}
    <DatagridConfigurable bulkActionButtons={<PrenotazioneBulkActionButtons />}> 
      {/*<TextField source="id" /> */}
      <TextField source="source" />      
      <ReferenceField source="ente_fornitore_id" reference="enti" label="Fornitore">
        <TextField source="nome" />
      </ReferenceField>
      <DateField source="data_attivita" label="Data prenotazione" locales="it-IT" showTime={false}/>      
      <TextField source="ora_inizio_attivita" label="Ora inizio attività"/>
      <TextField source="ora_fine_attivita" label="Ora fine attività"/>
      <ReferenceField source="ente_fruitore_id" reference="enti" label="Fruitore">
        <TextField source="nome" />
      </ReferenceField>            
      <ReferenceField source="circuito_id" reference="circuiti" label="Circuito">
        <TextField source="circuito" />
      </ReferenceField>
      <TextField source="partecipanti" label="Numero partecipanti"/>
      <TextField source="fascia_eta" label="Fascia d'età"/>
      <TextField source="eta_partecipanti" label="Età partecipanti"/>   
      <TextField source="eventuali_disabilita" label="Eventuali disabilità"/>      
      <TextField source="descrizione" label="Titolo attività" />
      <TextField source="durata_attivita" label="Durata attività"/>            
      <TextField source="accompagnatori" label="Numero accompagnatori"/>      
      <TextField source="referente" />
      <TextField source="telefono_referente" label="Telefono referente"/>      
      <MyEmailField source="mail_referente" label="Email referente"/>      
      <TextField source="ingresso" label="Ingresso/Punto di ritrovo"/>      
      <TextField source="note" />      
      {/*<TextField source="inserimento" />   */}             
      {/*<EditButton/> */}
    </DatagridConfigurable>
  </List>
);

//NOTA BENE source va popolata a zero in caso di inserimento manuale





/*
export const PrenotazioniEdit = () => (
  <Edit title={<username />} mutationMode="pessimistic"> 
    <SimpleForm>    
      <TextInput source="circuito"  />      
    </SimpleForm>
  </Edit>
);

export const CircuitoCreate = () => (
  <Create>
    <SimpleForm>    
      <TextInput source="circuito"  />      
    </SimpleForm>
  </Create>
);
*/

