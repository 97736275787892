import { Admin, Login } from 'react-admin';

const LoginXKE = () => (
    <Login
        // A random image that changes everyday
        //backgroundImage="https://source.unsplash.com/random/1600x900/daily"
        //backgroundImage="./logo192.png"
        //backgroundImage="./logo512.png"
        //backgroundImage="./sit_323.png"
        //background="#ff0000"

    />
);

const App = () => (
    <Admin loginPage={LoginXKE}>
        // ...
    </Admin>
);
export default LoginXKE;