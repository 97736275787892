//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { SimpleList, Datagrid, TextField, EmailField, UrlField, EditButton, TopToolbar } from "react-admin";
import { Edit, Create, DateField, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { Fragment,useRef,useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { MyEmailField,MyEmptyDateField } from "./MyUrlField";
import { DateInput } from 'react-admin';
import { useEditContext,useGetOne } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import { CreateButton, FilterButton, ExportButton } from "ra-ui-materialui";
import { Chip }  from '@mui/material';
//import { makeStyles} from '@mui/styles';
import { List, downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useTranslate } from 'react-admin';
import React, { useState } from 'react';
import { useNotify, useRefresh, useRecordContext, useRedirect, useDataProvider } from 'react-admin';
import Modal from 'react-modal';
import { useImport } from 'react-admin-import-csv';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { useGetList } from 'react-admin';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import {properties} from "../utils/properties";
import Tooltip from '@mui/material/Tooltip';
import {validateCodiceFiscaleEnhanced,validateCodiceFiscale, validateString, validateCapOrNull, validateStringOrNull, validateEmail, validatePhoneNumber, validateDateOrNull, validateDate, validateTesseraMusei, validateSex} from './validations'
import {comuni_json} from "../components/LBSbirthplace"
//import { makeStyles } from '@material-ui/core/styles';
var CodiceFiscale = require('codice-fiscale-js');

//const [additionalInfo, setAdditionalInfo] = useState('');
//const [modalOpen, setModalOpen] = useState(false);
var modalOpen;
var additionalInfo;


const renderTooltipContent = (record) => {
  if(record.error==1){    
    record.tooltipId="Il bambino è già presente nella tabella Registrazioni con le stesse informazioni (Codice Fiscale, Circuito ed Ente)";
  }
  else{
    if(record.error==2){
      record.tooltipId="Il bambino è stato inserito due volte in questa tabella";  
    }
    else{
      if(record.error==0){
        record.tooltipId="Il bambino è stato validato correttamente";  
      }
      else{
        record.tooltipId="Il bambino non è stato validato correttamente. Effettua la validazione";  
      }
    }
  }
  return (<span>{record.tooltipText}</span>);
  };

/*
const CustomRow = ({ record, resource, id, children, ...rest }) => {
  console.row("CR",record,resource,id,children);
  const tooltipId = `tooltip-${resource}-${id}`;
  if(record.error==1){    
    record.tooltipId="Il bambino è già presente nella tabella Registrazioni con le stesse informazioni (Codice Fiscale, Circuito ed Ente)";
  }
  else{
    if(record.error==2){
      record.tooltipId="Il bambino è stato inserito due volte in questa tabella";  
    }
    else{
      if(record.error==0){
        record.tooltipId="Il bambino è stato validato correttamente";  
      }
      else{
        record.tooltipId="Il bambino non è stato validato correttamente. Effettua la validazione";  
      }
    }
  }

  return (
    <div data-tip={record.tooltipText} data-for={tooltipId} {...rest}>
      {children}
      <ReactTooltip id={tooltipId} effect="solid" place="right" />
    </div>
  );
}; 
*/

const handleSubmit = async (file) => {
  console.log("AA222")
  if (!additionalInfo) {
    //notify('Please provide additional information');
    console.log("AA223")
    return;
  }

  try {
    // Perform your custom logic here using the file and additionalInfo.
    // You can make an API call, update the database, or perform any other required actions.
    console.log("AA224")
    // Show success notification and refresh the admin view.
    //notify('Import successful');
    //refresh();
  } catch (error) {
    // Handle any errors that occur during the import process.
    console.log("AA225")
    //notify('Error occurred during import', 'error');
  }
};

const handleAdditionalInfoChange = (event) => {
  additionalInfo=event.target.value;
};

const openModal = () => {
  modalOpen=true;
};

const closeModal = () => {
  modalOpen=false;
};

//const notify = useNotify();
//const refresh = useRefresh();

const ImportBulkActionButtons = () => (
  <Fragment>
      {/*<BulkExportButton />      */}
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);

/*const useQuickFilterStyles = makeStyles(theme => ({
  chip: {
      marginBottom: theme.spacing(1),
  },
})); */

const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  //const classes = useQuickFilterStyles();
  return <Chip label={translate(label)} />;
};

const ImportFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,
  <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} filter={{tipologia_id: 2}} sort={{ field: 'nome', order: 'ASC' }}>
      <SelectInput option="nome" optionText="nome" />
  </ReferenceInput>,
  //<TextInput label="Import da CSV" source="questionario_id" defaultValue="" />
  //<TextInput label="Ente" source="ente" defaultValue="" />,
  <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000} sort={{ field: 'circuito', order: 'ASC' }}>
      <SelectInput option="circuito" optionText="circuito" />
  </ReferenceInput> ,
  //<TextInput label="Circuito" source="circuito" defaultValue="SNVIV" />
  <TextInput label="Cognome" source="cognome" />,    
];

/*
const exporter = (records, fetchRelatedRecords) => {
      let data;
      let data1;


fetchRelatedRecords(records, 'ente_id', 'enti').then(enti => {
    //console.log("I1:", records);    
    data1 = records.map(record => ({
          ...record,
          ente: enti[record.ente_id].nome,                
    })); 
    //console.log("EXPORT-1",data1);
    //console.log("EXP3",JSON.stringify(data));
    fetchRelatedRecords(data1,'circuito_id','circuiti').then(circuiti =>{
        //console.log("I2",data1);
        data = data1.map(record => ({
        ...record,
        circuito: circuiti[record.circuito_id].circuito,                
    })); 
    //console.log("EXPORT-2",data);
    
    return jsonExport(data, {
      headers: ['id', 'questionario_id','ente', 'circuito', "timestamp_submit","cognome", "nome", "sesso", "data_nascita", "stato_nascita", 
      "provincia_nascita", "luogo_nascita", "indirizzo_residenza", "citta_residenza", "cap_residenza", "provincia_residenza", "codice_fiscale", 
      "codice_abbonamento_musei",  "scadenza_abbonamento_musei", "confermaPresaDAtto", "presaDAtto1","presaDAtto", "cognome_genitore",
      "nome_genitore", "email_genitore", "telefono_genitore"],
  }, (err, csv) => {
      downloadCSV(csv, 'questionari');
  });
  })
    
});
};
*/
  
const requestValidation = ()=> {
  //const refresh = useRefresh();
  const xhr = new XMLHttpRequest(); 
  
  xhr.open('GET', `${properties.productionUrl}/rest/valida`, true)
  xhr.onload = () => {
    if(xhr.status===200) {
      const entiJ = JSON.parse(xhr.response)
      console.log("Validazione in corso 3");
      //redirect("/imports");
      //refresh();       
    }
  }
  xhr.send(null)
}


/*
const Empty = () => (
  <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
          Nessun record da validare
      </Typography>
      <Typography variant="body1">
          Scarica il modello o importa un file
      </Typography>
      <a href={require("../assets/bambini.csv")} download="bambini">Scarica</a>
      <a href="../assets/bambini.csv" download="MyExampleDoc" target='_blank'>
        <Button >Scarica</Button>
      </a>        
      <MyComponent />    
  </Box>
);
*/

export const ImportList = (props) => {

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  //const dataProvider = useDataProvider();
  //const classes = useStyles();
  //console.log("CALLED");
  //refresh();



/*  const callValidation = async () => {
    const response = await fetch(`${properties.productionUrl}/rest/valida`)
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }
  */
  const callValidation = ()=> {
    const xhr = new XMLHttpRequest(); 
    
    xhr.open('GET', `${properties.productionUrl}/rest/valida`, true)
    xhr.onload = () => {
      if(xhr.status===200) {
        const entiJ = JSON.parse(xhr.response)
        console.log("Validazione in corso 1");     
        redirect("/imports");
        refresh();     
        //redirect("/imports");
        /*setTimeout(function(){
          console.log("timeout fired")
          refresh();
      }, 500);*/
        
      }
    }
   xhr.send(null);


  }

  const requestValidationInner = ()=> {
    const xhr = new XMLHttpRequest(); 
    
    xhr.open('GET', `${properties.productionUrl}/rest/valida`, true)
    xhr.onload = () => {
      if(xhr.status===200) {
        const entiJ = JSON.parse(xhr.response)
        console.log("Validazione in corso 2");
        redirect("/imports");
        refresh();       
      }
    }
    xhr.send(null)
  }
  

  const requestValidationAndSend = ()=> {
    const xhr = new XMLHttpRequest(); 
    
    xhr.open('GET', `${properties.productionUrl}/rest/valida`+"?import", true)
    xhr.onload = () => {
      if(xhr.status===200) {
        const jsonData = JSON.parse(xhr.response);              
        var rows=jsonData["rows"]; 
        if(jsonData["status"]=="error"){
           //error o insert
           notify("Errore durante la validazione delle righe inserite.", { messageArgs: { smart_count: {rows} } });
        }
        else{
           //ok
           notify("Sono state correttamente importate tutte le righe.", { messageArgs: { smart_count: {rows} } });
        }        
        redirect("/imports");
        refresh();
        //console.log("Validazione in corso")        
      }
    }  
    xhr.send()
  }

  

  const ImportActions = (props) => {
    const {
      className,
      basePath,
      //total,
      resource,
      currentSort,
      filterValues,
      exporter,
    } = props;
    return (
      <TopToolbar className={className}>             
         <a href={require("../assets/bambini.xls")} download="template.xls" target='_blank' style={{textDecoration: 'none'}}>                 
         <Button
              size="small"              
              label="Valida">Scarica Template XLS
         </Button>
         </a>
         <a href={require("../assets/bambini.csv")} download="template.csv" target='_blank' style={{textDecoration: 'none'}}>                 
         <Button
              size="small"              
              label="Valida">Scarica Template CSV
         </Button>
         </a>
         <Button
              size="small"
              onClick={() => { requestValidationInner();}}
              label="Valida">Valida
          </Button>
        <Button
              size="small"
              onClick={() => { requestValidationAndSend();}}
              label="Valida e Carica"
          >Valida e Carica
          </Button>
        <FilterButton/>        
        <MyComponent />
      </TopToolbar>
    );
  };




  const getRowStyle = (record,index) => {  
  
    //console.log("GRS",record,index);  
    if (record.error==1) {
      //console.log("GRS-INTO",record.error);
      return {backgroundColor:'#FE3F39', title:'"Il bambino è già presente nella tabella Registrazioni con le stesse informazioni (Codice Fiscale, Circuito ed Ente)"'};
    }
    else {
      if(record.error==0)
        return {backgroundColor:'#8CFB44', title: "Il bambino è stato validato correttamente"};
      else   {
        if(record.error==2)
        return {backgroundColor:'#FAF844', title:"Il bambino è stato inserito due volte in questa tabella"};
      else   {
        if(record.error==3)
          return {backgroundColor:'#D5C4F7', title:"Il bambino ha dei dati in un formato errato"};
          //return {backgroundColor:'#FED139', title:"Il bambino ha dei dati in un formato errato"};
        else    
          return {backgroundColor:'white', title:"Il bambino non è ancora stato validato. Effettua la validazione"};
      } 
        
      } 
       
    }
  };
  //empty={<Empty/>}
  return(    
  <List {...props} filters={ImportFilters} perPage={50} actions={<ImportActions/>}>  
    <Datagrid rowStyle={getRowStyle} bulkActionButtons={<ImportBulkActionButtons />}> 
    {/*<TextField source="error" />    */}    
    {/* <TextField source="id" />    */}
    {/*<TextField source="questionario_id" />*/}   
    <ReferenceField source="ente_id" reference="enti" label="Ente">
        <TextField source="nome" />
    </ReferenceField>
    <ReferenceField source="circuito_id" reference="circuiti" label="Circuito">
        <TextField source="circuito" />
    </ReferenceField>
    {/*<TextField source="gruppo_id" /> */}
    {/*<TextField source="timestamp_submit" label="Data completamento"/> */}           
    <TextField source="cognome" />      
    <TextField source="nome" />        
    <TextField source="sesso" />    
    {/*<TextField source="data_nascita" label="Data di nascita"/> */}
    <DateField source="data_nascita" label="Data di nascita" locales="it-IT"  options={{ timeZone: 'UTC' }} showTime={false}/>
    {/*<DateInput source="data_nascita" label="Data di nascita" locales="it-IT" />*/}
    <TextField source="stato_nascita" label="Stato di nascita"/>
    <TextField source="provincia_nascita" label="Provincia di nascita"/>
    <TextField source="luogo_nascita" label="Luogo di nascita"/>
    <TextField source="indirizzo_residenza" label="Indirizzo di residenza"/>
    <TextField source="citta_residenza" label="Città di residenza"/>
    <TextField source="cap_residenza" label="CAP di residenza"/>
    <TextField source="provincia_residenza" label="Provincia di residenza"/>    
    <TextField source="codice_fiscale" label="Codice fiscale"/>
    <TextField source="codice_abbonamento_musei" label="Codice abbonamento musei"/>
    <MyEmptyDateField source="scadenza_abbonamento_musei" label="Scadenza abbonamento musei" locales="it-IT"  options={{ timeZone: 'UTC' }} showTime={false}/>
    {/*<TextField source="scadenza_abbonamento_musei" label="Scadenza abbonamento musei"/> */}    
    <TextField source="cognome_genitore" label="Cognome genitore"/>
    <TextField source="nome_genitore" label="Nome genitore"/>
    <MyEmailField source="email_genitore" label="E-mail genitore"/>
    <TextField source="telefono_genitore" label="Telefono genitore"/>
      <EditButton/>
    </Datagrid>
  </List>)
};

export const ImportEdit = () => {
  //const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { id } = useParams();
  const { isLoading, data } = useGetOne("imports", { id });


/*
  const CHECK_CODE_ODD = {
    0: 1,
    1: 0,
    2: 5,
    3: 7,
    4: 9,
    5: 13,
    6: 15,
    7: 17,
    8: 19,
    9: 21,
    A: 1,
    B: 0,
    C: 5,
    D: 7,
    E: 9,
    F: 13,
    G: 15,
    H: 17,
    I: 19,
    J: 21,
    K: 2,
    L: 4,
    M: 18,
    N: 20,
    O: 11,
    P: 3,
    Q: 6,
    R: 8,
    S: 12,
    T: 14,
    U: 16,
    V: 10,
    W: 22,
    X: 25,
    Y: 24,
    Z: 23
  };
  
  const CHECK_CODE_EVEN = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
    K: 10,
    L: 11,
    M: 12,
    N: 13,
    O: 14,
    P: 15,
    Q: 16,
    R: 17,
    S: 18,
    T: 19,
    U: 20,
    V: 21,
    W: 22,
    X: 23,
    Y: 24,
    Z: 25
  };

  const CHECK_CODE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const validateCodiceFiscaleEnhanced=(value)=>{
    var result=false;
    if(value.length!=16){
      result= "Il campo codice fiscale è errato. Controllare la lunghezza";
      return false;
    }
    var cf2=value.substring(11, 15);
    console.log("WER1",cf2)
    if(!comuni_json.some(item => item[0] === cf2))
      return false;    
    let val = 0
    for (let i = 0; i < 15; i = i + 1) {
        const c = value[i]
        val += i % 2 !== 0 ? CHECK_CODE_EVEN[c] : CHECK_CODE_ODD[c]
    }
    val = val % 26;
    console.log("321",CHECK_CODE_CHARS.charAt(val), value.charAt(15));
    if(CHECK_CODE_CHARS.charAt(val)!=value.charAt(15))
      return false;    
    return true;  
  }  
*/


  const handleEdit = async (values) => {
    try {
      //VALIDAZIONE TO DO
      //var data2=transform(values);
      //console.log("Editing",data2,values);
      //notify("Reset password avvenuto con successo! Una mail è stata inviata all'indirizzo inserito con la password generata", { messageArgs: { smart_count: 1 } });
      //redirect('/users');
      // Your additional actions here      
      //var data2=transform(values);
      //console.log("Editing",data2,values);
      //const { data } = await dataProvider.update('imports', { id:data2.id, data: data2 });  
      const { data } = await dataProvider.update('imports', { id:values.id, data: values });  
      requestValidation();
      redirect('/imports');
      refresh();
      //requestValidationInner();
      
    } catch (error) {
      // Handle error
    }
  };
/*
  const validateTesseraMusei=(value)=>{
    if(value==""||value==undefined||value==null)
      return true;
    var result=false;
    var re = new RegExp("^[Aa]{1}[Mm]{1}[Pp]{1}[0-9]{9}$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
  }


  const validateSex=(value)=>{
    var result=false;
    let re = new RegExp("^\[mfMF]{1}$");
    if (re.test(value)) {
      result=true;
    } else {
      result=false;
    }
  return result;
  }

  const validateCap=(value)=>{
    var result=false;   
    var re = new RegExp("^\\d{5}$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
  return result;
  }

  const validateString=(value)=>{
    var result=false;
    var re = new RegExp("^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9-' ]*$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
  }


  const validateCapOrNull=(value)=>{
    if(value==""||value==null||value==undefined)
      return true;
    var result=false;   
    var re = new RegExp("^\\d{5}$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
  return result;
  }

  const validateStringOrNull=(value)=>{
    if(value==""||value==null||value==undefined)
      return true;
    var result=false;
    var re = new RegExp("^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9-' ]*$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
  }
  
  const validateDate=(value)=>{
    var result=false;
    let re = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
    if (re.test(value)) {
      result=true;
    } else {
      let re2 = new RegExp("^\\d{2}-\\d{2}-\\d{4}$");
      if (re2.test(value)) {        
        result=true;
      } else {
        result=false;
    }
  }
  return result;
  }

  const validateDateOrNull=(value)=>{
    var rr=value;
    if (rr==""||rr==null||rr==undefined)
      rr="1970-01-01";      
    var result=false;
    let re = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
    if (re.test(rr)) {
      result=true;
    } else {
      result=false;
    }
  return result;
  }

  const validateEmail=(value)=>{
    var result=false;

    //let re = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
    //let re =new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/);
    //let re = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.)+[^<>()\.,;:\s@\"]{2,})$/);
    let re  = new RegExp(/^[a-zA-Z0-9'+._-]{1,}@[a-zA-Z0-9._-]{1,}\.[a-zA-Z]{1,}$/);
    if (re.test(value)) {
      result=true;
    } else {
      result=false;
    }
  return result;
  }
*/
/*
  const validatePhoneNumber=(value)=>{
    var result=false;
    var re = new RegExp("^[0-9- +']*$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
  }
*/

  const validateCFFields = (values) => {
    const errors = {};

    if(!validateString(values.cognome))
      errors.cognome="Il campo cognome contiene dei caratteri non supportati";
    if(!validateString(values.nome))
      errors.nome="Il campo nome contiene dei caratteri non supportati";
    if(!validateString(values.stato_nascita))
      errors.stato_nascita="Il campo stato di nascita contiene dei caratteri non supportati";
    if(!validateStringOrNull(values.provincia_nascita))
      errors.provincia_nascita="Il campo provincia di nascita contiene dei caratteri non supportati";  
    if(!validateStringOrNull(values.luogo_nascita))
      errors.luogo_nascita="Il campo luogo di nascita contiene dei caratteri non supportati";
    if(!validateString(values.indirizzo_residenza))
      errors.indirizzo_residenza="Il campo indirizzo di residenza contiene dei caratteri non supportati";
    if(!validateString(values.citta_residenza))
      errors.citta_residenza="Il campo città di residenza contiene dei caratteri non supportati";
    if(!validateStringOrNull(values.provincia_residenza))
      errors.provincia_residenza="Il campo provincia di residenza contiene dei caratteri non supportati";      
    if(!validateString(values.cognome_genitore))
      errors.cognome_genitore="Il campo cognome genitore contiene dei caratteri non supportati";
    if(!validateString(values.nome_genitore))
      errors.nome_genitore="Il campo nome genitore contiene dei caratteri non supportati";
    if(!validateEmail(values.email_genitore))
      errors.email_genitore="Il campo e-mail è in un formato non corretto";  
    if(!validatePhoneNumber(values.telefono_genitore))
      errors.telefono_genitore="Il campo numero di telefono contiene caratteri non supportati";  
    if(!validateTesseraMusei(values.codice_abbonamento_musei))
      errors.codice_abbonamento_musei="Il campo tessera musei è in un formato non corretto";  
    if(!validateDateOrNull(values.scadenza_abbonamento_musei))
      errors.scadenza_abbonamento_musei="Il campo data scadenza abbonamento musei è in un formato non corretto";  
    if(!validateSex(values.sesso))
      errors.sesso="Il campo sesso contiene dei caratteri non supportati";    
    if(!validateDate(values.data_nascita))
      errors.data_nascita="Il campo data di nascita è in un formato non corretto";    
    if(!validateCapOrNull(values.cap_residenza))
      errors.cap_residenza="Il campo CAP è in un formato non corretto";          
    var data=values.data_nascita;
    var cf=values.codice_fiscale;
    var cfBuilder = new CodiceFiscale({ 
      name: values.nome,
      surname: values.cognome,
      gender: values.sesso,
      day: data.substring(8,10),
      month: data.substring(5,7),
      year: data.substring(0,4),
      birthplace: "TORINO"
     });
     if(cfBuilder.code.substring(0,11)!=values.codice_fiscale.substring(0,11))
      errors.codice_fiscale="Il codice fiscale inserito non è coerente con i dati anagrafici"
     //console.log("CHECK",cfBuilder.code, values.codice_fiscale);
    if(!validateCodiceFiscaleEnhanced(values.codice_fiscale))
      errors.codice_fiscale="Il campo codice fiscale è errato";      
    return errors
  };

return(  
  <Edit title={<username />} mutationMode="pessimistic"> 
    <SimpleForm onSubmit={handleEdit} validate={validateCFFields} sx={{ maxWidth: '100%' }}>
    <Typography variant="h6" gutterBottom>
                Dati importati
      </Typography>           
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} filter={{tipologia_id: 2}} sort={{ field: 'nome', order: 'ASC' }}>
              <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome" options={{fullWidth:true}} fullWidth/>
            </ReferenceInput>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000} sort={{ field: 'circuito', order: 'ASC' }}>
              <SelectInput sx={{textAlign: "left"}} option="circuito" optionText="circuito" options={{fullWidth:true}} fullWidth/>
            </ReferenceInput> 
          </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="cognome" fullWidth/>
            </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="nome" fullWidth/>
          </Box>
      </Box>  

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <SelectInput sx={{textAlign: "left"}}  source="sesso"  options={{fullWidth:true}} fullWidth
        choices={[{ id: 'M', name: 'M' },{ id: 'F', name: 'F' } ]}/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="data_nascita" label="Data di nascita" locales="it-IT" fullWidth/>
        </Box>
      </Box>
   
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="stato_nascita" label="Stato di nascita" fullWidth/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="provincia_nascita" label="Provincia di nascita" fullWidth/>
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="luogo_nascita" label="Luogo di nascita" fullWidth/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="codice_fiscale" label="Codice fiscale" fullWidth/>
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="indirizzo_residenza" label="Indirizzo di residenza" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="citta_residenza" label="Città di residenza" fullWidth/>
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="cap_residenza" label="CAP di residenza" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="provincia_residenza" label="Provincia di residenza" fullWidth/>
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="cognome_genitore" label="Cognome genitore" fullWidth/>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="nome_genitore" label="Nome genitore" fullWidth/>
          </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="email_genitore" label="E-mail genitore" fullWidth/>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="telefono_genitore" label="Telefono genitore"fullWidth/>
          </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="codice_abbonamento_musei" label="Codice abbonamento musei" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="scadenza_abbonamento_musei" label="Scadenza abbonamento musei" locales="it-IT" fullWidth/>
        </Box>
      </Box>      
    </SimpleForm>
  </Edit>);
};







function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [selectedEnte, setSelectedEnte] = React.useState('');
  const [entiData, setEntiData] = useState([]);
  const [selectedCB, setSelectedCB] = React.useState(false);
  const [isEnabled, setisEnabled] = React.useState(false);
  const [selectedCircuito, setSelectedCircuito] = React.useState('');
  const [circuitiData, setCircuitiData] = useState([]);

  const handleChange = (event) => {
    setSelectedCircuito(event.target.value);
    if(selectedCB && selectedEnte != "" && event.target.value != "")
      setisEnabled(true);
    else
      setisEnabled(false);

    console.log("Selected Value Circuito", selectedCircuito, event.target.value);
  };


  const handleChangeCB = (event) => {    
    setSelectedCB(event.target.checked );
    if(event.target.checked && selectedEnte != "" && selectedCircuito != "")
      setisEnabled(true);
    else
      setisEnabled(false);
    console.log("Selected CB", selectedCircuito, selectedEnte, event.target.checked);
  };


  const handleChangeEnte = (event) => {
    setSelectedEnte(event.target.value);
    if(selectedCB && selectedCircuito != "" && event.target.value != "")
      setisEnabled(true);
    else
      setisEnabled(false);
    console.log("Selected Value Ente", selectedEnte, event.target.value);
  };
  //const { data, isLoading } = useGetList('circuiti');
  const handleClose = () => {
    setisEnabled(false);
    setSelectedCB(false);
    setSelectedEnte("");
    setSelectedCircuito("");
    onClose(selectedValue);
  };

  useEffect(() => {       
    requestEnti();       
    requestCircuiti();              
  }, []);

  
  


  const requestEnti = ()=> {
    const xhr = new XMLHttpRequest(); 
    
    xhr.open('GET', `${properties.productionUrl}/php/core/api/api.php/records/enti`, true)
    xhr.onload = () => {
      if(xhr.status===200) {
        const entiJ = JSON.parse(xhr.response)
        //console.log(entiJ)
        var enti = Object.entries(entiJ);                            
        //console.log("CC",enti[0],enti[0][1]);
        setEntiData(enti[0][1]);        
      }
    }
    xhr.send(null)
  }



  const requestCircuiti = ()=> {
    const xhr = new XMLHttpRequest(); 
    
    xhr.open('GET', `${properties.productionUrl}/php/core/api/api.php/records/circuiti`, true)
    xhr.onload = () => {
      if(xhr.status===200) {
        const circJ = JSON.parse(xhr.response)
        //console.log(circJ)
        var circs = Object.entries(circJ);                            
        //console.log("CC",circs[0],circs[0][1]);
        setCircuitiData(circs[0][1]);        
      }
    }
    xhr.send(null)
  }


  const handleClick = () => {
    console.log("22222222");
    //selectedValue=selectedEnte+"-"+selectedCircuito;
    var elements=document.getElementsByClassName('MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-16zittn');
    console.log("00000000", elements);
    var ind=-1;
    let i;

    for (i = 0; i < elements.length; i++) {
      //console.log(i, elements[i].attributes,  elements[i].attributes.getNamedItem("aria-label"),elements[i].attributes.getNamedItem("aria-label").value);
      if (elements[i].ariaLabel=="Import"||elements[i].attributes.getNamedItem("aria-label").value=="Import"){
        console.log("found",i);
        elements[i].style.display = "none";
        ind=i;
      }    
    }    
   
    if( ind != -1){
        const customImportButton = elements[ind];
        if (customImportButton) {
            console.log("11111111");
            console.log("11111",selectedCircuito);
            customImportButton.click();
        }
    }
    //onClose(selectedValue);
    onClose(selectedEnte+"-"+selectedCircuito);
  };


  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>IMPORT CSV</DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Prima di procedere al caricamento dei dati è necessario selezionare l'ente ed il circuito a cui i dati importati verranno associati.            
          </DialogContentText>      
          <FormControl fullWidth>
              <InputLabel id="ente-select-label">Ente</InputLabel>
              <Select
                labelId="ente-select-label"
                id="ente-select"
                value={selectedEnte}
                label="Ente"
                onChange={handleChangeEnte}
                
              >
                {entiData.map((ente) => (
                <MenuItem
                  key={ente.id}
                  value={ente.id}                  
                >
              {ente.nome}
            </MenuItem>
          ))}           
            </Select>
          </FormControl>

          <FormControl fullWidth>
              <InputLabel id="circuito-select-label">Circuito</InputLabel>
              <Select
                labelId="circuito-select-label"
                id="circuito-select"
                value={selectedCircuito}
                label="Circuito"
                onChange={handleChange}
              >
                {circuitiData.map((circuito) => (
                <MenuItem
                  key={circuito.id}
                  value={circuito.id}                  
                >
              {circuito.circuito}
            </MenuItem>
          ))}           
            </Select>
          </FormControl>
          <FormControlLabel required control={<Checkbox />} onChange={handleChangeCB} label="Dichiaro che l'Ente ha acquisito e trasmette i dati presenti nell'archivio di Import CSV senza violare alcuna legge, regolamento e qualsiasi altra disposizione relativa alla protezione dei dati personali, previa informazione alle famiglie sul trattamento dei loro dati personali compresi i soggetti minorenni, anche per la comunicazione a Xkè ZeroTredici s.c.r.l. e ad Abbonamento Musei per l'iniziativa La Bella Stagione 2024." />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Chiudi</Button>
          <Button disabled={isEnabled ? false : true} onClick={handleClick}>Carica CSV</Button>
        </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  //returnValue: PropTypes
};



export const MyComponent = () => {
  const [open, setOpen] = React.useState(false);
  //const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);  
  const [selectedValue, setSelectedValue] = React.useState("Ciao");
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [circA1, setCircA1] = React.useState();
  const [entA1, setEntA1] = React.useState();

  //var circA1;
  //var entA1=;

  /*const setCircuito = (val) => {
    console.log("transforming row");    
    circA1=val;  
  };*/  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    console.log("HCE2",value);    
    var values=value.split("-");
    setEntA1(values[0]);
    setCircA1(values[1]);
    setOpen(false);
    setSelectedValue(value);
  };

 
/*
  const validateTesseraMusei=(value)=>{
    if(value=="")
      return true;
    var result=false;
    var re = new RegExp("^[Aa]{1}[Mm]{1}[Pp]{1}[0-9]{9}$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
  }

  const validateProvince=(value)=>{
    var result=false;
    let re = new RegExp("^\[a-zA-Z]{2}$");
    if (re.test(value)) {
      result=true;
    } else {
      result=false;
    }
  return result;
  }

  const validateSex=(value)=>{
    var result=false;
    let re = new RegExp("^\[mfMF]{1}$");
    if (re.test(value)) {
      result=true;
    } else {
      result=false;
    }
  return result;
  }

  const validateCap=(value)=>{
    var result=false;   
    var re = new RegExp("^\\d{5}$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
  return result;
  }

  const validateString=(value)=>{
    var result=false;
    var re = new RegExp("^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9-' ]*$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
  }
  
  const validateStringOrNull=(value)=>{
    if(value==""||value==undefined||value==null)
      return true;
    var result=false;
    var re = new RegExp("^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9-' ]*$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
  }

  const validateCapOrNull=(value)=>{
    if(value==""||value==undefined||value==null)
    return true;
    var result=false;   
    var re = new RegExp("^\\d{5}$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
  return result;
  }

  const validateDate=(value)=>{
    var result=false;
    let re = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
    if (re.test(value)) {
      result=true;
    } else {
      let re2 = new RegExp("^\\d{2}-\\d{2}-\\d{4}$");
      if (re2.test(value)) {        
        result=true;
      } else {
        result=false;
    }
  }
  return result;
  }

  const validateDateOrNull=(value)=>{
    var rr=value;
    if (rr=="")
      rr="1970-01-01";      
    var result=false;
    let re = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
    if (re.test(rr)) {
      result=true;
    } else {
      result=false;
    }
  return result;
  }

  const validateEmail=(value)=>{
    var result=false;
    //let re = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
    //let re =new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/);
    //let re = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.)+[^<>()\.,;:\s@\"]{2,})$/);
    let re  = new RegExp(/^[a-zA-Z0-9'+._-]{1,}@[a-zA-Z0-9._-]{1,}\.[a-zA-Z]{1,}$/);
    if (re.test(value)) {
      result=true;
    } else {
      result=false;
    }
  return result;
  }
*/
/*
  const validateCodiceFiscale=(value)=>{
    var result=false;
    if(value.length!=16)
      result= false;
    else
      result= true;    
    return result;
  }
  */
/*
  const validatePhoneNumber=(value)=>{
    var result=false;
    var re = new RegExp("^[0-9- +']*$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
  }
*/

  const handleTransformRows = (rows) => {
    var arr = Object.keys(rows[0]);
    console.log("1-TR",rows, rows[0],rows[0].length,rows[0]);
    console.log("1-TR1-",arr.length, arr);
    //if (rows[0].length!=18) {       
    if (arr.length!=18) {       
      console.log("INSIDE 1-TR");
      notify("ERRORE: Il numero di colonne non è quello previsto. Si prega di scaricare il template e verificare la coerenza dei dati caricati con il template",{ type: 'error', autoHideDuration:0 });
      throw new Error('Il numero di colonne non è quello previsto. Si prega di scaricare il template e verificare la coerenza dei dati caricati con il template.');      
    }

    var err123="";
    
    if (rows[0].cognome == undefined)
        err123=err123+" cognome";
    if (rows[0].nome== undefined)
      err123=err123+" nome";
    if (rows[0].sesso== undefined)
      err123=err123+" sesso";
    if (rows[0].data_nascita== undefined)
      err123=err123+" data_nascita";
    if (rows[0].stato_nascita== undefined)
      err123=err123+" stato_nascita";
    if (rows[0].provincia_nascita== undefined)
      err123=err123+" provincia_nascita";
    if (rows[0].luogo_nascita== undefined)
      err123=err123+" luogo_nascita";
    if (rows[0].indirizzo_residenza== undefined)
      err123=err123+" indirizzo_residenza";
    if (rows[0].citta_residenza== undefined)
      err123=err123+" citta_residenza";
    if (rows[0].cap_residenza== undefined)
      err123=err123+" cap_residenza";
    if (rows[0].provincia_residenza== undefined)
      err123=err123+" provincia_residenza";
    if (rows[0].codice_fiscale== undefined)
      err123=err123+" codice_fiscale";
    if (rows[0].codice_abbonamento_musei== undefined)
      err123=err123+" codice_abbonamento_musei";
    if (rows[0].scadenza_abbonamento_musei== undefined)
      err123=err123+" scadenza_abbonamento_musei";
    if (rows[0].cognome_genitore== undefined)
      err123=err123+" cognome_genitore";
    if (rows[0].nome_genitore== undefined)
      err123=err123+" nome_genitore";
    if (rows[0].email_genitore== undefined)
      err123=err123+" email_genitore";
    if (rows[0].telefono_genitore== undefined)
      err123=err123+" telefono_genitore";
                    
    if(err123!="") {
      console.log("INSIDE ERR123");
      notify("ERRORE: I seguenti campi non sono presenti nel file importato o hanno una intestazione errata: "+err123+" Correggerli e riprovare", { type: 'error', autoHideDuration:0 });
      throw new Error("ERRORE: I seguenti campi non sono presenti nel file importato o hanno una intestazione errata: "+err123+" Correggerli e riprovare");      
    }
    err123="";
    /*ITERARE SU TUTTE LE RIGHE PER CERCARE CARATTERI PROBLEMATICI -> VALIDAZIONE */
    for (let i = 0; i < rows.length; i++) {
      err123="";
      var err124="";
      let riga=rows[i];    
      console.log("123",riga, riga.cognome); 
      //COGNOME
      if (riga.cognome != undefined){
        if(!validateString(riga.cognome))
            err124=err124+" cognome";
      }
      else
          err123=err123+" cognome";
      //NOME
      if (riga.nome != undefined){
        if(!validateString(riga.nome))
          err124=err124+" nome";
      }
      else
        err123=err123+" nome";
      //STATO NASCITA
      if (riga.stato_nascita != undefined){
          if(!validateString(riga.stato_nascita))
            err124=err124+" stato_nascita";
      }
        else
          err123=err123+" stato_nascita";
      //LUOGO NASCITA
      if (riga.luogo_nascita != undefined){
        //if(!validateString(riga.luogo_nascita))
        if(!validateStringOrNull(riga.luogo_nascita))
          err124=err124+" luogo_nascita";
      }
      else
        err123=err123+" luogo_nascita";
      //EMAIL
        if (riga.email_genitore != undefined){
          if(!validateEmail(riga.email_genitore))
            err124=err124+" email_genitore";
      }
      else
          err123=err123+" email_genitore";
      //INDIRIZZO RESIDENZA
      if (riga.indirizzo_residenza != undefined){
          if(!validateString(riga.indirizzo_residenza))
            err124=err124+" indirizzo_residenza";
      }
      else
            err123=err123+" indirizzo_residenza";   
      //CITTA RESIDENZA
      if (riga.citta_residenza != undefined){
          if(!validateString(riga.citta_residenza))
              err124=err124+" citta_residenza";
      }
      else
              err123=err123+" citta_residenza";  
      //COGNOME GENITORE
      if (riga.cognome_genitore != undefined){
          if(!validateString(riga.cognome_genitore))
              err124=err124+" cognome_genitore";
      }
      else
              err123=err123+" cognome_genitore";
      //NOME GENITORE
      if (riga.nome_genitore != undefined){
        if(!validateString(riga.nome_genitore))
            err124=err124+" nome_genitore";
      }
      else
            err123=err123+" nome_genitore";
      //TELEFONO GENITORE
      if (riga.telefono_genitore != undefined){
        if(!validatePhoneNumber(riga.telefono_genitore))
            err124=err124+" telefono_genitore";
      }
      else
            err123=err123+" telefono_genitore";   
      //CAP 
       if (riga.cap_residenza != undefined){
        //if(!validateCap(riga.cap_residenza))
        if(!validateCapOrNull(riga.cap_residenza))
            err124=err124+" cap_residenza";
      }
      else
            err123=err123+" cap_residenza";   
      //CODICE ABBON MUSEI
      if (riga.codice_abbonamento_musei != undefined){
        if(!validateTesseraMusei(riga.codice_abbonamento_musei))
            err124=err124+" codice_abbonamento_musei";
      }
      else
            err123=err123+" codice_abbonamento_musei";      
      //DATA NASCITA
      if (riga.data_nascita != undefined){
        if(!validateDate(riga.data_nascita))
            err124=err124+" data_nascita";
      }
      else
            err123=err123+" data_nascita";
      //PROVINCIA RESIDENZA      
      if (riga.provincia_residenza != undefined){
        //if(!validateString(riga.provincia_residenza))
        if(!validateStringOrNull(riga.provincia_residenza))
            err124=err124+" provincia_residenza";
      }
      else
            err123=err123+" provincia_residenza";
     //PROVINCIA NASCITA
      if (riga.provincia_nascita != undefined){
        //if(!validateString(riga.provincia_nascita))
        if(!validateStringOrNull(riga.provincia_nascita))
            err124=err124+" provincia_nascita";
      }
      else
            err123=err123+" provincia_nascita";
      //SESSO
      if (riga.sesso != undefined){
        if(!validateSex(riga.sesso))
            err124=err124+" sesso";
      }
      else
            err123=err123+" sesso";
      //SCADENZA TESSERA
      if (riga.scadenza_abbonamento_musei != undefined){
        if(!validateDateOrNull(riga.scadenza_abbonamento_musei))
            err124=err124+" scadenza_abbonamento_musei";
      }
      else
            err123=err123+" scadenza_abbonamento_musei";
      //CODICE FISCALE      
      if (riga.codice_fiscale != undefined){
        if(!validateCodiceFiscale(riga.codice_fiscale))
            err124=err124+" codice_fiscale";
      }
      else
            err123=err123+" codice_fiscale";
      /*
      if (riga.telefono_genitore != undefined){
        if(!validatePhoneNumber(riga.telefono_genitore))
            err124=err124+" telefono_genitore";
      }
      else
            err123=err123+" telefono_genitore";
      */
      if (err124!="" || err123!=""){
        console.log("INSIDE ERR123||ERR124");
        if(err124!=""){
          console.log("EE1",err123,err124,riga);
          notify("ERRORE: I seguenti campi non sono presenti nel file importato o hanno una intestazione errata: "+err124+" all'interno della riga:"+JSON.stringify(riga)+ " Correggerli e riprovare", { type: 'error', autoHideDuration:0 });
          throw new Error("ERRORE: I seguenti campi non sono presenti nel file importato o hanno una intestazione errata: "+err124+" all'interno della riga:"+JSON.stringify(riga)+ " Correggerli e riprovare");      
        }else{
          console.log("EE2",err123,err124,riga);
          notify("ERRORE: I seguenti campi non sono presenti nel file importato o hanno una intestazione errata: "+err123+" all'interno della riga:"+JSON.stringify(riga)+ " Correggerli e riprovare", { type: 'error', autoHideDuration:0 });
          throw new Error("ERRORE: I seguenti campi non sono presenti nel file importato o hanno una intestazione errata: "+err123+" all'interno della riga:"+JSON.stringify(riga)+ " Correggerli e riprovare");      
        }     
      }      
    }
    
    console.log("TRA1");
    const transformedRows = rows.map((row) => {
      if(row.scadenza_abbonamento_musei=="")
        row.scadenza_abbonamento_musei="1970-01-01";      
      if(row.scadenza_abbonamento_musei!="")  {        
          row.scadenza_abbonamento_musei=row.scadenza_abbonamento_musei.replaceAll("/","-"); 
          let re = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
          if (!re.test(row.scadenza_abbonamento_musei)) {
            let re2 = new RegExp("^\\d{2}-\\d{2}-\\d{4}$");
            if (re2.test(row.scadenza_abbonamento_musei)) {        
              row.scadenza_abbonamento_musei=row.scadenza_abbonamento_musei.substring(6,10)+"-"+row.scadenza_abbonamento_musei.substring(3,5)+"-"+row.scadenza_abbonamento_musei.substring(0,2);
            }
          }
      }  
    if(row.data_nascita!="")  {        
          row.data_nascita=row.data_nascita.replaceAll("/","-"); 
          let re = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
          if (!re.test(row.data_nascita)) {
              let re2 = new RegExp("^\\d{2}-\\d{2}-\\d{4}$");
              if (re2.test(row.data_nascita)) {        
                row.data_nascita=row.data_nascita.substring(6,10)+"-"+row.data_nascita.substring(3,5)+"-"+row.data_nascita.substring(0,2);
              }
          }  
      }
    row.circuito_id=circA1; 
    row.ente_id=entA1;  
    return row;
    });
    console.log("TRA2",transformedRows);
    return transformedRows;
  };

  const [data, setData] = useState('');

/*
  const validateRowCb= (row) => {
    console.log('validateRow', row);

    if(row.cap_residenza.length!=5)
      row.error=3;
  
    return row;
   
  };
*/

const requestValidationForPostCommitCb = ()=> {  
  const xhr = new XMLHttpRequest(); 
  
  xhr.open('GET', `${properties.productionUrl}/rest/valida`, true)
  xhr.onload = () => {
    if(xhr.status===200) {
      const entiJ = JSON.parse(xhr.response)
      console.log("Validazione in corso 5");
      redirect("/imports");
      refresh();       
    }
  }
  xhr.send(null)
}


  //const postCommitCb = (row, index, status) => {
  const postCommitCb = (rows) => {
    console.log("3-PCC",rows);
    //console.log("3-PCC");
    
    //requestValidation();
    requestValidationForPostCommitCb();
    //console.log("After RV");
    //redirect("/imports");
    //refresh();   
    console.log("After RF2");
    //redirect("/imports");
    //requestValidationInner();
    //refresh();
    return null;
    //return rows;
  };
  
  return (
    <div>
      {/*<button onClick={handleOtherButtonClick}>Other Button</button> */}
      <Button size="small" startIcon={<FileUploadIcon />} variant="text" onClick={handleClickOpen} >IMPORT</Button>
      <div style={{ display: "none" }}>
        {/*<ImportButton style={{ display: "none" }} sx={{ display: "none" }} postCommitCallback={postCommitCb} validateRow={validateRowCb} transformRows={handleTransformRows}/> */}
        <ImportButton style={{ display: "none" }} sx={{ display: "none" }} parseConfig={{delimiter:""}} postCommitCallback={postCommitCb} transformRows={handleTransformRows}/>
      </div>    
      <SimpleDialog
        selectedValue={selectedValue}        
        open={open}
        onClose={handleClose}        
      /> 
    </div>
  );
};