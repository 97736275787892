//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, SimpleList, Datagrid, TextField, EmailField, UrlField } from "react-admin";
import { Edit, Create, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput, EditButton} from "react-admin";
import { BulkDeleteButton, BulkExportButton, Toolbar, SaveButton  } from 'react-admin';
import { MyEmailField } from "./MyUrlField";
import { MyUrlField } from "./MyUrlField";
import { Fragment } from 'react';
import { Box, Typography } from "@mui/material";

const EnteBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton />      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);

const EnteFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,  
];


const transform = (data) => {
  console.log("TRANSFORMING",data);
  const sanitizedData = {};
  for (const key in data) {
      //if (typeof data[key] === "string" && data[key].trim().length === 0) continue;
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["parent_id"]=localStorage.getItem("ente");
  console.log("TRANSFORMING3",sanitizedData);
  return sanitizedData;
};


export const EnteList = () => (
  <List filters={EnteFilters} perPage={50}>
    {/*<Datagrid rowClick="edit">*/}
    <Datagrid bulkActionButtons={<EnteBulkActionButtons />}> 
      <TextField source="id" />
      <TextField source="nome" />
      <TextField source="ragione_sociale" />
      <TextField source="referente" />
      <TextField source="telefono" />
      {/*<EmailField source="email" />      */}
      {/*<MyEmailField source="email" label="E-mail"/> */}
      <MyEmailField source="email_privacy" label="E-mail privacy"/>
      <MyEmailField source="email_referente" label="E-mail referente"/>
      <MyUrlField source="sito_web" label="Sito Web"/>   
      <TextField source="indirizzo" />
      <TextField source="codice_am" label="Codice Abbonamento Musei"/>
      {/*<TextField source="privacy_link" />*/}
      <MyUrlField source="privacy_link" />   
      <ReferenceField source="tipologia_id" reference="tipologia" label="Tipologia">
        <TextField source="tipo" />
      </ReferenceField>
      {/*<TextField source="parent_id " />
      <TextField source="tipologia_id" /> */}  
      <ReferenceField source="parent_id" reference="enti" label="Parent">
        <TextField source="nome" />
      </ReferenceField>
      <EditButton/>
    </Datagrid>
  </List>
);


const EnteEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);



export const EnteEdit  = () => {
  return(  
  <Edit title={<username />} mutationMode="pessimistic"> 
    <SimpleForm sx={{ maxWidth: '100%' }} toolbar={<EnteEditToolbar />} >      
        <Typography variant="h6" gutterBottom>
                    Dati ente
        </Typography>   
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="nome"  fullWidth disabled={true}/>           
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <ReferenceInput source="tipologia_id" reference="tipologia" label="Tipologia" perPage={1000}>      
                      <SelectInput option="tipo" optionText="tipo"  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth disabled={true}/>
                  </ReferenceInput>    
                  
              </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="ragione_sociale" fullWidth />                        
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="referente" fullWidth />
              </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="email_privacy" fullWidth  />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="email_referente" fullWidth />
              </Box>
        </Box>
      
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="sito_web" fullWidth  />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="indirizzo" fullWidth  />
              </Box>
        </Box>
      
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="telefono" fullWidth />                  
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="privacy_link" fullWidth />
              </Box>
        </Box>    

         <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="codice_am" fullWidth />                  
              </Box>              
        </Box>   

    </SimpleForm>
  </Edit>
)
};

export const EnteCreate =  ({ onSuccess, ...props }) => {
  return(  
      <Create transform={transform}  {...props} >   
        <SimpleForm sx={{ maxWidth: '100%' }}> 
            <Typography variant="h6" gutterBottom>
                        Dati ente
            </Typography>   
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput source="nome"  fullWidth />           
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <ReferenceInput source="tipologia_id" reference="tipologia" label="Tipologia" perPage={1000}>      
                          <SelectInput option="tipo" optionText="tipo"  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth />
                      </ReferenceInput>                                          
                  </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput source="ragione_sociale" fullWidth />          
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TextInput source="referente" fullWidth />
                  </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput source="email_privacy" fullWidth  />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TextInput source="email_referente" fullWidth />
                  </Box>
            </Box>
          
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput source="sito_web" fullWidth  />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TextInput source="indirizzo" fullWidth  />
                  </Box>
            </Box>
          
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput source="telefono" fullWidth />                  
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TextInput source="privacy_link" fullWidth />
                  </Box>
            </Box>    

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="codice_am" fullWidth />                  
              </Box>              
        </Box>   

        </SimpleForm>
      </Create>
  )
};

