//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, SimpleList, Datagrid, TextField, EmailField, UrlField } from "react-admin";
import { Edit, Create, SimpleForm, ReferenceInput, TextInput, EditButton} from "react-admin";
import { Fragment } from 'react';
import { BulkDeleteButton, BulkExportButton } from 'react-admin';


const CircuitoBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton />      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);

const CircuitoFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,  
];

export const CircuitoList = () => (
  <List filters={CircuitoFilters}>
    {/*<Datagrid rowClick="edit">  */}
    <Datagrid bulkActionButtons={<CircuitoBulkActionButtons />}> 
      <TextField source="id" />
      <TextField source="circuito" />      
      <EditButton/>
    </Datagrid>
  </List>
);

export const CircuitoEdit = () => (
  <Edit title={<username />} mutationMode="pessimistic"> 
    <SimpleForm>
      {/*<ReferenceInput source="userId" reference="users" fullWidth options={{ autoWidth: true }} /> */}
      {/*<TextInput source="id" /> */}
      {/*<TextInput source="id"  />*/}
      <TextInput source="circuito"  />      
    </SimpleForm>
  </Edit>
);

export const CircuitoCreate = () => (
  <Create>
    <SimpleForm>    
      <TextInput source="circuito"  />      
    </SimpleForm>
  </Create>
);

