
import {properties} from "../utils/properties";
import { EvaluateAdmin } from "./MyAdmin";
import { useStore } from 'react-admin';
import React from "react";
import { useNotify, useRefresh, useRedirect} from 'react-admin';

let onAdminStatusChange = null;

export const setAdminStatusChangeCallback = (callback) => {
  onAdminStatusChange = callback;
};

export const authProvider = {

  // called when the user attempts to log in
  login: ({ username, password }) => {
  console.log("LOGGING IN");
  const request = new XMLHttpRequest();
  request.open('POST', `${properties.productionUrl}/login_php`);
  request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  request.send(`user=${username}&password=${password}`);
  return new Promise((resolve, reject) => {
    request.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          console.log("success",this.responseText);          
          let jsonData = JSON.parse(this.responseText);          
          localStorage.setItem("ruolo", jsonData["ruolo"]);
          localStorage.setItem("ente", jsonData["ente"]);
          localStorage.setItem("username", username);          
          if(jsonData["ruolo"]=='COMPAGNIA'){
            localStorage.setItem("admin",JSON.stringify(3));                
            if (onAdminStatusChange) {
              onAdminStatusChange(3);
            }            
          }
          else if(jsonData["ruolo"]=='ADMIN' ||jsonData["ruolo"]=='ADMIN-ENTE'){                  
            //localStorage.setItem("admin",JSON.stringify(true));            
            if(jsonData["ruolo"]=='ADMIN'){
              localStorage.setItem("admin",JSON.stringify(2));            
              if (onAdminStatusChange) {
                onAdminStatusChange(2);
              }            
            }
            else{
              localStorage.setItem("admin",JSON.stringify(1));            
              if (onAdminStatusChange) {
                onAdminStatusChange(1);
              }            
            }
            console.log("admin1 true");  
            /*if (onAdminStatusChange) {
              onAdminStatusChange(true);
            } */           
          }
          else{                        
            //localStorage.setItem("admin",JSON.stringify(false));            
            localStorage.setItem("admin",JSON.stringify(0));            
            console.log("admin1 false");  
            //if (onAdminStatusChange) {
            //  onAdminStatusChange(false);
            //}            
            if (onAdminStatusChange) {
              onAdminStatusChange(0);
            }            
          }   
          resolve();         
        } else {
          reject('Credenziali errate');
        }
      }
    };
  });

  },
  // called when the user clicks on the logout button
  logout: () => { 
    
    const request = new XMLHttpRequest();
    request.open('POST', `${properties.productionUrl}/logout_php`);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    let urlEncodedData = null;
    request.send(urlEncodedData);
    return new Promise((resolve, reject) => {
      request.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            console.log("logout success");
            localStorage.removeItem("username");
            localStorage.removeItem("ruolo");
            localStorage.removeItem("ente");
            localStorage.removeItem("admin");
            resolve();            
          } else {
            localStorage.removeItem("username");
            localStorage.removeItem("ruolo");
            localStorage.removeItem("ente");
            localStorage.removeItem("admin");
            resolve();  
          }
        }
      };
    });

       
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    console.log("checkError");    

    if (status === 401 || status === 403) {
      /*
      localStorage.removeItem("username");
      localStorage.removeItem("ruolo");
      localStorage.removeItem("ente");
      */
      return Promise.reject();
    }
    return Promise.resolve();
   
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    console.log("checkAuth");
    
    const request = new XMLHttpRequest();
    request.open('POST', `${properties.productionUrl}/rest/ping`);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    let urlEncodedData = null;
    request.send(urlEncodedData);
    return new Promise((resolve, reject) => {
      request.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            
            console.log("PING OK",this.responseText);                        
            let jsonData = JSON.parse(this.responseText);          
            //if(localStorage.getItem("ruolo")==null||localStorage.getItem("ente")==null||localStorage.getItem("username")==null||localStorage.getItem("admin")==null){
              if(localStorage.getItem("ruolo")==null||localStorage.getItem("ente")==null||
                localStorage.getItem("username")==null||localStorage.getItem("admin")==null ||
                localStorage.getItem("ruolo")!=jsonData["ruolo"]||localStorage.getItem("ente")!= jsonData["ente"]||
                localStorage.getItem("username")!=jsonData["username"]){
                    console.log("something is missing in the localstorage - ping", localStorage);
                    localStorage.setItem("ruolo", jsonData["ruolo"]);
                    localStorage.setItem("ente", jsonData["ente"]);              
                    localStorage.setItem("username", jsonData["username"]);
                    if(jsonData["ruolo"]=='COMPAGNIA'){
                      localStorage.setItem("admin",JSON.stringify(3));                
                      if (onAdminStatusChange) {
                        onAdminStatusChange(3);
                      }            
                    }
                    else if(jsonData["ruolo"]=='ADMIN' ||jsonData["ruolo"]=='ADMIN-ENTE'){                        
                      if(jsonData["ruolo"]=='ADMIN'){
                        localStorage.setItem("admin",JSON.stringify(2));                
                        if (onAdminStatusChange) {
                          onAdminStatusChange(2);
                        }            
                      }
                      else{
                        localStorage.setItem("admin",JSON.stringify(1));                
                        if (onAdminStatusChange) {
                          onAdminStatusChange(1);
                        }            
                      }
                      console.log("admin12 true");    
                      //if (onAdminStatusChange) {
                      //  onAdminStatusChange(true);
                      //}                          
                    }
                    else{                
                      //localStorage.setItem("admin",JSON.stringify(false));                
                      localStorage.setItem("admin",JSON.stringify(0));                
                      console.log("admin12 false");                  
                      //if (onAdminStatusChange) {
                      //  onAdminStatusChange(false);
                      //}            
                      if (onAdminStatusChange) {
                        onAdminStatusChange(0);
                      }            
                    }   
            }            
            resolve();            
          } else {
            reject("Necessario effettuare il login",this.responseText);
          }
        }
      };
    });
  },

  // called when the user navigates to a new location, to check for permissions / roles
  getIdentity: () => {
  try {      
      const id="user";
      const fullName=localStorage.getItem("username");
      const avatar="";
      return Promise.resolve({ id, fullName, avatar });
  } catch (error) {
      return Promise.reject(error);
  }
},

getPermissions: () => {
    console.log("getPermissions");    
    Promise.resolve();
},
  
};