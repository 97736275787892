//import { List, Datagrid, TextField, EmailField } from "react-admin";
//import { List, SimpleList, Datagrid, TextField, EmailField, UrlField } from "react-admin";
//import { Edit, Create, SimpleForm, ReferenceInput, TextInput} from "react-admin";
import { List, downloadCSV } from 'react-admin';
//import jsonExport from 'jsonexport/dist';
import { Datagrid, TextField, RichTextField, ReferenceField, EditButton } from "react-admin";
import { MyUrlField } from "./MyUrlField";
import { Edit, Create, SimpleForm, ReferenceInput, DateField, TextInput, UrlField, AutocompleteInput, SelectInput} from "react-admin";
import { properties } from "../utils/properties";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { Fragment } from 'react';
import { DateTimeInput } from 'react-admin';

const QuestionarioBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton />      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);

const QuestionarioFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,  
];




export const QuestionarioList = () => (
  <List  filters={QuestionarioFilters} perPage={50}>
    {/*<Datagrid rowClick="edit"> */}
    <Datagrid bulkActionButtons={<QuestionarioBulkActionButtons />}> 
      <TextField source="id" />
      <ReferenceField source="ente_id" reference="enti" label="Ente">
        <TextField source="nome" />
      </ReferenceField>
      <ReferenceField source="circuito_id" reference="circuiti" label="Circuito">
        <TextField source="circuito" />
      </ReferenceField>
      {/*<TextField source="ente_id" /> */}
      {/*<TextField source="data_termine" label="Termine validità"/> */}
      <DateField source="data_termine" label="Termine validità" locales="it-IT"  options={{ timeZone: 'UTC' }} showTime={true}/>
      {/*<TextField source="link" />     */}
      <MyUrlField source="link" />   
      <TextField source="counter_submit" label="Numero registrazioni"/>     
      <EditButton/>
    </Datagrid>
  </List>
);

export const QuestionarioEdit = () => (
  <Edit title={<username />} mutationMode="pessimistic"> 
    <SimpleForm>
      {/*<ReferenceInput source="userId" reference="users" fullWidth options={{ autoWidth: true }} /> */}
      {/*<TextInput source="id" /> */}
      {/*<TextInput source="id"  /> */}
      {/* <TextInput source="ente_id"  /> */}
      <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} filter={{tipologia_id: 2}} sort={{ field: 'nome', order: 'ASC' }}>
      {/*  <AutocompleteInput optionText="nome" /> */}
        <SelectInput option="nome" optionText="nome" />
      </ReferenceInput>
      <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000} sort={{ field: 'circuito', order: 'ASC' }}>
        <SelectInput option="circuito" optionText="circuito" />
      </ReferenceInput>
      {/*<TextInput source="data_termine" label="Termine validità"/>*/}
      <DateTimeInput source="data_termine" label="Termine validità" locales="it-IT" />
      {/*<TextInput source="link" />      */}
    </SimpleForm>
  </Edit>
);

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}


const transform = (data) => {
  console.log("TRANSFORMING",data);
  const sanitizedData = {};
  for (const key in data) {
      //if (typeof data[key] === "string" && data[key].trim().length === 0) continue;
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["link"]=properties.productionUrl+"/survey/"+makeid(8);
  console.log("TRANSFORMING2",sanitizedData);
  return sanitizedData;
};

export const QuestionarioCreate = () => (  
  <Create transform={transform}>
    <SimpleForm>    
    {/*  <TextInput source="id"  />*/}
      {/* <TextInput source="ente_id"  /> */}
      <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} filter={{tipologia_id: 2}} sort={{ field: 'nome', order: 'ASC' }}>        
        {/*<AutocompleteInput optionText="nome" /> */}
        <SelectInput option="nome" optionText="nome" />
      </ReferenceInput>      
      <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000} sort={{ field: 'circuito', order: 'ASC' }}>        
        <SelectInput option="circuito" optionText="circuito" />
      </ReferenceInput>      
     {/*<TextInput source="data_termine" label="Termine validità"/>*/}
     <DateTimeInput source="data_termine" label="Termine validità" locales="it-IT" />
      {/*<TextInput source="link" />            */}
      {/*<TextInput source="parent_id" />*/}
      {/*<TextInput source="tipologia_id" /> */}
    </SimpleForm>
  </Create>
);

