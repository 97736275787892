//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, SimpleList, Datagrid, DatagridConfigurable,TextField, EmailField, UrlField,  TopToolbar, SelectColumnsButton, ExportButton  } from "react-admin";
import { Edit, Create, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput, EditButton} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { MyEmailField } from "./MyUrlField";
import { MyUrlField } from "./MyUrlField";
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { Fragment } from 'react';

const EnteCSPBulkActionButtons = () => (  
  <Fragment>
    <BulkExportButton  exporter={exporter1} maxResults= {15000}/>   
  </Fragment>
);

const EnteCSPFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,  
];

const exporter1 = (records, fetchRelatedRecords) => {  
  let data1;
  let dataForExport;

  fetchRelatedRecords(records, 'tipologia_id', 'tipologia').then(tipologia => {
      data1 = records.map(record => ({
      ...record,
      tipo: tipologia[record.tipologia_id].tipo,                
  })); 

  dataForExport = data1.map(record => {        
    const { referente,tipologia_id,email_privacy,email_referente,parent_id,privacy_link, ...dataForExport } =record;
    return dataForExport;
  });
 
  jsonExport(dataForExport, {
    headers: [ 'id','nome', 'ragione_sociale', 'telefono','sito_web', 'indirizzo', 'tipo', 'codice_am'],
  }, (err, csv) => {
    downloadCSV(csv, 'enti');
  });
})

};


const EnteCSPActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />                        
        <ExportButton         
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter1}
          maxResults= {15000}
        />    
    </TopToolbar>
  );
};




export const EnteCSPList = (props) => (  
    <List {...props} filters={EnteCSPFilters} perPage={50} actions={<EnteCSPActions />}>  
    {/*<Datagrid rowClick="edit">*/}
    {/*<Datagrid bulkActionButtons={<EnteNoAdminBulkActionButtons />}> */}
    <DatagridConfigurable bulkActionButtons={<EnteCSPBulkActionButtons />}> 
      <TextField source="id" />
      <TextField source="nome" />
      <TextField source="ragione_sociale" />      
      <TextField source="telefono" />
      <MyUrlField source="sito_web" label="Sito Web"/>   
      <TextField source="indirizzo" />       
      <TextField source="codice_am" label="Codice Abbonamento Musei"/>
      <ReferenceField source="tipologia_id" reference="tipologia" label="Tipologia">
        <TextField source="tipo" />
      </ReferenceField>            
    </DatagridConfigurable>
  </List>
);



