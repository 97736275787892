import { List, SimpleList, Datagrid, TextField, ChipField, SingleFieldList, ReferenceArrayField, EmailField, UrlField, ReferenceField, TopToolbar, SelectColumnsButton, ExportButton } from "react-admin";
import { Edit,ReferenceManyCount, DatagridConfigurable, SelectArrayInput, Create, SimpleForm, ReferenceInput, TextInput, EditButton,  DateField, SelectInput, DateInput, ReferenceArrayInput, AutocompleteArrayInput} from "react-admin";
import { Fragment, useEffect } from 'react';
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { useParams, useNavigate } from "react-router-dom";
import { MyEmailField} from "./MyUrlField";
import { Grid } from "@mui/material";
import { downloadCSV } from 'react-admin';
import { FilterButton } from "ra-ui-materialui";
import jsonExport from 'jsonexport/dist';


import Chip from '@mui/material/Chip';
import { useRecordContext,useEditContext,useGetOne } from 'react-admin';

const ConsuntivazioneCSPBulkActionButtons = () => (
  <Fragment>      
      <BulkExportButton exporter={exporter1} maxResults= {15000}/>      
     {/** <BulkDeleteButton mutationMode="pessimistic"/>  */}
  </Fragment>
);

const ConsuntivazioneCSPFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,  
  <ReferenceInput source="statoprenotazione_id" reference="statiprenotazioni" label="Stato" perPage={1000}>
    <SelectInput sx={{textAlign: "left"}} option="stato" optionText="stato" />
  </ReferenceInput>, 
  <ReferenceInput source="ente_fruitore_id" reference="enti" label="Ente Fruitore" perPage={1000} filter={{tipologia_id: 2}} sort={{ field: 'nome', order: 'ASC' }}>     
  <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"/>
</ReferenceInput>,
<ReferenceInput source="ente_fornitore_id" reference="enti" label="Ente Fornitore" perPage={1000} filter={{tipologia_id: 1}} sort={{ field: 'nome', order: 'ASC' }}>     
  <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"/>
</ReferenceInput>,
  <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000} sort={{ field: 'circuito', order: 'ASC' }}>
      <SelectInput sx={{textAlign: "left"}} option="circuito" optionText="circuito" />        
  </ReferenceInput>
];


const FullNameChipField = ({ record }) => {
  const record2 = useRecordContext();  
  //console.log("FNCF",record,record2);
  const fullName = `${record2.cognome} ${record2.nome} (${record2.codice_fiscale})`;
  return <Chip label={fullName} />;
};

const BambinoIdField = ({ source }) => {  
  const record = useRecordContext();  
  const fullName = `${record.cognome} ${record.nome} (${record.codice_fiscale})`;
  //console.log("DER-FN",fullName);
  //console.log("DER", record[source]);
  var bambinoIds=record[source];
  //console.log("DER2", bambinoIds);
  if(bambinoIds!=null){
    return  (      
      <>
        {bambinoIds.map((id) => ( 
            <ReferenceField
            key={id}
            record={{ id }} 
            source="id"
            reference="bambini" 
            link={false} > 
                <FullNameChipField />
            </ReferenceField>
        ))}
    </> 
    );
  } 
};

const BambinoIdField2 = ({ source }) => {  
  const record = useRecordContext();  
  const fullName = `${record.cognome} ${record.nome} (${record.codice_fiscale})`;
  //console.log("DER-FN",fullName);
  //console.log("DER", record[source]);
  var size=0;
  var bambinoIds=record[source];
  //console.log("DER2", bambinoIds);
  if(bambinoIds!=null){
    size=bambinoIds.length;
  }    
    return  (      
      <> <Chip label={size} /> </> 
    );  
};

function getRuolo() {
  let result = localStorage.getItem("ruolo")  
  return result;
}

const exporter1 = (records, fetchRelatedRecords) => {
  let data;
  let data1;
  let data2;
  let data3;
  let dat;
  let dat1;
  let dataForExport;
  
const CHUNK_SIZE = 5; // Define the chunk size

const fetchDataInChunks = (data, field, relatedResource) => {
  const chunks = [];
  let currentChunk = [];

  for (let i = 0; i < data.length; i++) {
    const record = data[i];
    currentChunk.push(record);

    if (currentChunk.length === CHUNK_SIZE || i === data.length - 1) {
      chunks.push(currentChunk);
      currentChunk = [];
    }
  }

  const fetchPromises = chunks.map(chunk => {
    let doit=0;
    const ids = chunk.map(record => record[field]);
    for(let i =0;i <ids.length;i++){
      console.log("CIPPA",ids[i]);
      if(ids[i]!=null &&ids[i]!=undefined)
          doit=1;            
    }
    if(doit==1){
        console.log ("AAEE",ids, chunk);
        return fetchRelatedRecords(chunk, field, relatedResource);
    }
    else{
      console.log ("AAZZ",ids, chunk);
      return;
    }
  });

  return Promise.all(fetchPromises)
    .then(results => [].concat(...results));
};


  fetchRelatedRecords(records, 'ente_fornitore_id', 'enti').then(enti => {
    data1 = records.map(record => ({
      ...record,
      ente_fornitore: enti[record.ente_fornitore_id].nome,                
    })); 
    //console.log("I2",data1);
    //console.log("EXP3",JSON.stringify(data));
    fetchRelatedRecords(data1, 'statoprenotazione_id', 'statiprenotazioni').then(statiprenotazioni => {
      data2 = data1.map(record => ({
        ...record,
        stato_consuntivazione: statiprenotazioni[record.statoprenotazione_id]?.stato ||'',                
      })); 

    fetchRelatedRecords(data2,'ente_fruitore_id','enti').then(enti =>{
      //console.log("I3",data1);
      data3 = data2.map(record => ({
      ...record,
      ente_fruitore: enti[record.ente_fruitore_id].nome,                
      })); 
      /*fetchRelatedRecords(data3,'bambino_id', 'bambini').then(bambini =>{
        //console.log("I4",data);
        data = data3.map(record => ({
        ...record,
        partecipanti_effettivi: record.bambino_id ? record.bambino_id.length :0,        
      })); */
      fetchRelatedRecords(data3,'circuito_id','circuiti').then(circuiti =>{
        //console.log("I4",data);
        dat = data3.map(record => ({
        ...record,
        circuito: circuiti[record.circuito_id].circuito,        
      }));
      ///AZW
      /*  
        fetchRelatedRecords(dat,'bambino_id','bambini').then(bambini =>{
      
          //console.log("IS4",dat);
          dat1 = dat.map(record => {          
          let bambinoNames = '';
          if (Array.isArray(record.bambino_id)) {
            bambinoNames = record.bambino_id
            .map(id => bambini[id]?.cognome + " "+ bambini[id]?.nome)
            .filter(Boolean)
            .join(' + ');
          }  else {
            if(record.bambino_id!= undefined && record.bambino_id!= null)
              bambinoNames = bambini[record.bambino_id]?.cognome + " " + bambini[record.bambino_id]?.nome || '';
            else
              bambinoNames="";
          }
          return {
            ...record,
            bambini: bambinoNames, 
      */
      //AZW        
      console.log(getRuolo());
      if(getRuolo()=="ABBONAMENTI-MUSEI")                    {
        console.log("IT IS AM");
        fetchDataInChunks(dat, 'bambino_id', 'bambini').then(bambiniChunks => {
          console.log("IS4", dat);
          let tre=0;
          const bambini = Object.assign({}, ...bambiniChunks);
          const exportedData = [];

          dat.forEach(record => {
            if (Array.isArray(record.bambino_id)) {
              record.bambino_id.forEach(id => {
                const bambinoName = bambini[id]?.cognome+ " " +bambini[id]?.nome;
                const cf=bambini[id]?.codice_fiscale;
                if (bambinoName && cf) {
                  const exportedRow = {
                    ...record,
                    bambino: bambinoName,
                    codice_fiscale: cf,
                  };
                  exportedData.push(exportedRow);
                }
              });
            } else {
              const bambinoName = bambini[record.bambino_id]?.nome;
              const cf=bambini[record.bambino_id]?.codice_fiscale;
              if (bambinoName && cf) {
                const exportedRow = {
                  ...record,
                  bambino: bambinoName,
                  codice_fiscale: cf,
                };
                exportedData.push(exportedRow);
              }
            }
          });
      //console.log("OPPALA",dat1);
    ///AAAAA
    console.log("TES",exportedData)
      dataForExport = exportedData.map(record => {        
          const { inserimento, ente_fornitore_id, bambino_id, ente_fruitore_id, circuito_id, statoprenotazione_id,referente,telefono_referente,mail_referente,ingresso,note, ...dataForExport } =record;
          return dataForExport;
      });
       
    //}); 

    //return jsonExport(dat, {
      //return jsonExport(dataForExport, {
      jsonExport(dataForExport, {
      headers: ['id','source', 'stato_consuntivazione','ente_fornitore','data_attivita', 'ora_inizio_attivita', "ora_fine_attivita","ente_fruitore", "circuito", "partecipanti", "bambino", "codice_fiscale","fascia_eta", "eta_partecipanti", 
      "eventuali_disabilita", "descrizione", "durata_attivita", "accompagnatori"],
      }, (err, csv) => {
      downloadCSV(csv, 'consuntivazioni');
      });
    })
      }
      else { 
        console.log("IT IS NOT AM");
        //BZW
        //ZZZ-ZZZ
        fetchDataInChunks(dat, 'bambino_id', 'bambini').then(bambiniChunks => {
            console.log("IS4", dat);
            let tre=0;
            const bambini = Object.assign({}, ...bambiniChunks);
            console.log("AAWWW", bambini, bambiniChunks);            
            const dat1 = dat.map(record => {
            let bambinoNames = '';
            if (Array.isArray(record.bambino_id)) {
              //console.log("AASS", record.bambino_id.length);
              if(record.bambino_id != null && record.bambino_id != undefined)
                tre= record.bambino_id.length;
              else 
                tre= 0;
              bambinoNames = record.bambino_id
              .map(id =>  bambini[id]?.codice_fiscale)
              .filter(Boolean)
              .join(' + ');
            } else {
              if(record.bambino_id!= undefined && record.bambino_id!= null){
                bambinoNames = bambini[record.bambino_id]?.codice_fiscale || '';
                tre=1;
              }
              else{
                bambinoNames="";
                tre=0;
              }
          }
          return {
            ...record,
            bambini: bambinoNames,
            partecipanti_effettivi: tre, 
      //BZW      
    };
        });
        //console.log("OPPALA",dat1);
      ///AAAAA
        dataForExport = dat1.map(record => {        
            const { inserimento, ente_fornitore_id, bambino_id, ente_fruitore_id, circuito_id, statoprenotazione_id, id,referente,telefono_referente,mail_referente,ingresso,note, ...dataForExport } =record;
            return dataForExport;
        });
         
      //}); 

      //return jsonExport(dat, {
        //return jsonExport(dataForExport, {
        jsonExport(dataForExport, {
        headers: ['source', 'stato_consuntivazione','ente_fornitore','data_attivita', 'ora_inizio_attivita', "ora_fine_attivita","ente_fruitore", "circuito", "partecipanti", "partecipanti_effettivi", "bambini", "fascia_eta", "eta_partecipanti", 
        "eventuali_disabilita", "descrizione", "durata_attivita", "accompagnatori"],
        }, (err, csv) => {
        downloadCSV(csv, 'consuntivazioni');
        });
      })
    } //FINE ELSE
      //ZZZ-ZZZ
      //}
      ///AAAAA
      })
   // })
  })
      ///AAAAA
    })
  });
};



const ConsuntivazioneListActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />      
      <FilterButton/>            
      {/*<CreateButton basePath={basePath} /> */}
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter1}
        maxResults= {15000}
      />    
    </TopToolbar>
  );
};


export const ConsuntivazioneCSPList = (props) => {

  return (
  <List {...props}  sort={{ field: 'data_attivita', order: 'DESC' }} actions={<ConsuntivazioneListActions />} filters={ConsuntivazioneCSPFilters} perPage={50}>
    {/*<Datagrid rowClick="edit">  */}
    <DatagridConfigurable bulkActionButtons={<ConsuntivazioneCSPBulkActionButtons />}> 
    
    <TextField source="source" />   
      <ReferenceField source="statoprenotazione_id" reference="statiprenotazioni" label="Stato">
        <TextField source="stato" />
      </ReferenceField>

      <ReferenceField source="ente_fornitore_id" reference="enti" label="Fornitore">
        <TextField source="nome" />
      </ReferenceField>

      <DateField source="data_attivita" label="Data prenotazione" locales="it-IT" showTime={false}/>      
      <TextField source="ora_inizio_attivita" label="Ora inizio attività"/>
      <TextField source="ora_fine_attivita" label="Ora fine attività"/>
    
      <ReferenceField source="ente_fruitore_id" reference="enti" label="Fruitore">
        <TextField source="nome" />          
      </ReferenceField>
    
      <ReferenceField source="circuito_id" reference="circuiti" label="Circuito">
        <TextField source="circuito" />
      </ReferenceField> 
    
      <TextField source="partecipanti" label="Numero partecipanti registrati"/>      
      <BambinoIdField2 label="Numero partecipanti effettivi" source="bambino_id"/>
      <TextField source="fascia_eta" label="Fascia d'età"/>
      <TextField source="eta_partecipanti" label="Età partecipanti"/>   
      <TextField source="eventuali_disabilita" label="Eventuali disabilità"/>          
      <TextField source="descrizione" label="Descrizione" />    
      <TextField source="durata_attivita" label="Durata attività"/>          
      <TextField source="accompagnatori" label="Numero accompagnatori"/>      
              
    </DatagridConfigurable>
  </List>)
};


