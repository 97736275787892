//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, SimpleList, DateField, Datagrid, TextField, FunctionField, EmailField, UrlField, EditButton } from "react-admin";
import { Edit, Create, TopToolbar, SelectColumnsButton,CreateButton,ExportButton,DatagridConfigurable, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { useNotify, useRefresh, useRedirect, useDataProvider} from 'react-admin';
import { Fragment,useState } from 'react';
import { MyEmailField } from "./MyUrlField";
import jsSHA from 'jssha';
import { PasswordInput } from 'react-admin';
import Button from '@mui/material/Button';
import { useRecordContext } from 'react-admin';
import { properties } from "../utils/properties";
import SendIcon from '@mui/icons-material/Send';
import {  FilterButton } from "ra-ui-materialui";
import { Box, Typography } from "@mui/material";
//import {CustomButton} from "./CustomButton";

const UserBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton />      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);

const SendMailButton = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const record=useRecordContext(); 
  //console.log("PDPM0",dataProvider,notify,refresh,record);
  if (!record) return null;
  return  <Button variant="text" color="primary" size="small" endIcon={<SendIcon />}
  onClick={ async(e) => {
    var newPwd=makeid(8);         
    try {
      
      await dataProvider.update('users', {
        id: record.id,
        data: { ...record, password: password(newPwd) },
      });
      notify('La mail con la nuova password è stata inviata con successo');
      doResetMail(record.email,record.username,newPwd);   
      refresh();
    } catch (error) {
      notify('Si è verificato un errore con il reset della password', 'error');
    }
    e.stopPropagation(); 
   } }
>
  Reset Password
</Button>          
};

const UserFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,  
  <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>     
    <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"/>
  </ReferenceInput>,
  <ReferenceInput source="ruolo_id" reference="ruoli" label="Ruolo" perPage={1000} >     
    <SelectInput sx={{textAlign: "left"}} option="ruolo" optionText="ruolo"/>
  </ReferenceInput>,
];



const UserActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      <CreateButton basePath={basePath} /> 
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />    
    </TopToolbar>
  );
};



export const UserList = () => (
  <List  filters={UserFilters} perPage={50} actions={<UserActions />}>    
    <DatagridConfigurable bulkActionButtons={<UserBulkActionButtons />}> 
      {/*<TextField source="id" /> */}
      {/*<TextField source="ente_id" label="Ente"/> */}      
      <TextField source="username" />
      <TextField source="nome" />
      <TextField source="cognome" />
      <MyEmailField source="email" />
      <ReferenceField source="ente_id" reference="enti" label="Ente">
        <TextField source="nome" />
      </ReferenceField>
      {/*<TextField source="ruolo" />*/}
      <ReferenceField source="ruolo_id" reference="ruoli" label="Ruolo">
        <TextField source="ruolo" />
      </ReferenceField>
      {/*<TextField source="password" /> */}
      <FunctionField label="Password" render={record => `********`} />
      {/*<TextField source="creationtime" label="Data creazione"/> */}
      <DateField source="creationtime" label="Data creazione" locales={Intl.DateTimeFormat().resolvedOptions()}  showTime={true}/>
      
      <TextField source="telefono" />
      <EditButton/>
      <SendMailButton/>
    </DatagridConfigurable>
  </List>
);



export const UserEdit = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();


  const transform = (data) => {
    var pwd=data["password"];

    if(pwd.length < 40){
      //console.log("Minore21",data["email"],data["username"],data["password"],data);      
      pwd=password(pwd);          
      pwd=pwd.toUpperCase();
    }
    else{
      //console.log("Else21");
      //setPwdChanged(false);
    }
    console.log("FINE",pwd);
    const sanitizedData = {};
    for (const key in data) {
      //if (typeof data[key] === "string" && data[key].trim().length === 0) continue;
      if(key=="password")
        sanitizedData[key] = pwd; 
      else
        sanitizedData[key] = data[key]; 
    }
    sanitizedData["creationtime"]=new Date().toISOString().slice(0, 19).replace('T', ' ');
    //console.log("TRANSFORMING2",sanitizedData);
    return sanitizedData; 
};

  const handleEdit = async (values) => {
    try {
      var data2=transform(values);
      console.log("Editing",data2,values);
      const { data } = await dataProvider.update('users', { id:data2.id, data: data2 });      
    
      console.log('Resource updated successfully:', data, values);    
      if(values.password.length<40){
        doResetMail(values.email, values.username, values.password);              
        notify("Reset password avvenuto con successo! Una mail è stata inviata all'indirizzo inserito con la password generata", { messageArgs: { smart_count: 1 } });
        redirect('/users');
      }
      else{
        notify("Dati aggiornati con successo!", { messageArgs: { smart_count: 1 } });
        redirect('/users');
      }
            
    } catch (error) {
      // Handle error
    }
  };

  return(
  <Edit title={<username />} mutationMode="pessimistic" transform={transform}>     
      <SimpleForm onSubmit={handleEdit} sx={{ maxWidth: '100%' }}>  
      <Typography variant="h6" gutterBottom>
                Dati utente
      </Typography>       
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="username"  disabled={true} fullWidth/>       
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <PasswordInput source="password" fullWidth/>    
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="nome" fullWidth/>
            </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="cognome" fullWidth/>
          </Box>
      </Box>  

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="email" fullWidth/>
            </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="telefono" fullWidth/>
          </Box>
      </Box>  

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>     
              <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"  options={{fullWidth:true}} fullWidth/>
            </ReferenceInput>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <ReferenceInput source="ruolo_id" reference="ruoli" label="Ruolo" perPage={1000}>  
              <SelectInput sx={{textAlign: "left"}} option="ruolo" optionText="ruolo" options={{fullWidth:true}} fullWidth/>
            </ReferenceInput> 
          </Box>
      </Box>
    </SimpleForm>
  </Edit> );
};


function password(password) {
  const passwordBytes = new TextEncoder().encode(password);  
  const shaObj = new jsSHA('SHA-1', 'ARRAYBUFFER', {"encoding" : "UTF8", "numRounds" : 2});
  shaObj.update(passwordBytes.buffer);
  const hash = shaObj.getHash('HEX');
  return `*${hash.toUpperCase()}`;
}

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}



const transform2 = (data) => {
  var pwd=data["password"];

  if(pwd.length < 40){
    //console.log("Minore2",data["email"],data["username"],data["password"],data);        
    pwd=password(pwd);        
    pwd=pwd.toUpperCase();
  }
  //console.log("FINE2",pwd);
  const sanitizedData = {};
  for (const key in data) {
    //if (typeof data[key] === "string" && data[key].trim().length === 0) continue;
    if(key=="password")
      sanitizedData[key] = pwd; 
    else
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["creationtime"]=new Date().toISOString().slice(0, 19).replace('T', ' ');
  //console.log("TRANSFORMING2",sanitizedData);
  return sanitizedData; 
};


function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
  }));
}


const doResetMail = function(email,username,password) {    
  var xhr = new XMLHttpRequest();        
  xhr.open('POST', properties.productionUrl+'/rest/doSend',true);
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhr.onload = function (e) {      
    if (xhr.readyState === 4) {
      if (xhr.status != 200) {                     
        console.error("Error 16", xhr.statusText);          
        alert("Reset Password fallito");  
      }
    }
  };  
  xhr.onerror = function (e) {
    console.error("Error 26", xhr.statusText);
  };      
  let urlEncodedData = "mail=" + email +"&user="+ username+"&password="+ b64EncodeUnicode(password)+"&action=reset"; 
  xhr.send(urlEncodedData);
}

const doRegisterMail = function(email,password,username) {    
  var xhr = new XMLHttpRequest();          
  xhr.open('POST', properties.productionUrl+'/rest/doSend',true);
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");  
  xhr.onload = function (e) {      
    if (xhr.readyState === 4) {
      if (xhr.status != 200) {                     
        console.error("Error 16", xhr.statusText);          
        alert("Reset Password fallito");  
      }
    }
  };
  xhr.onerror = function (e) {
    console.error("Error 26", xhr.statusText);
  };    
  let urlEncodedData = "mail=" + email +"&password="+ b64EncodeUnicode(password)+"&action=register"+"&user="+ username; 
  xhr.send(urlEncodedData);
}

  export const UserCreate = ({ onSuccess, ...props }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    
    const handleCreate = async (values) => {
      try {
        var data2=transform2(values);
        const { data } = await dataProvider.create('users', { data: data2 });
        
        // Custom logic after successful creation
        console.log('Resource created successfully:', data, values);
        doRegisterMail(values.email, values.password, values.username);        
        notify("Utente creato correttamente! Una mail è stata inviata all'indirizzo inserito con la password generata", { messageArgs: { smart_count: 1 } });
        redirect('/users');
        // Your additional actions here
      } catch (error) {
        notify("Qualcosa è andato storto nella creazione dell'utente. Riprovare più tardi");
      }
    };

  return(
  <Create transform={transform2}  {...props} >   
    <SimpleForm onSubmit={handleCreate} sx={{ maxWidth: '100%' }}>  
<Typography variant="h6" gutterBottom>
          Dati utente
</Typography>       
<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
    <TextInput source="username" fullWidth/>       
  </Box>
  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
    <PasswordInput source="password" fullWidth/>    
  </Box>
</Box>

<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
    <TextInput source="nome" fullWidth/>
      </Box>
    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
      <TextInput source="cognome" fullWidth/>
    </Box>
</Box>  

<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
    <TextInput source="email" fullWidth/>
      </Box>
    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
      <TextInput source="telefono" fullWidth/>
    </Box>
</Box>  

<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
    <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>     
        <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"  options={{fullWidth:true}} fullWidth/>
      </ReferenceInput>
    </Box>
    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
    <ReferenceInput source="ruolo_id" reference="ruoli" label="Ruolo" perPage={1000}>  
        <SelectInput sx={{textAlign: "left"}} option="ruolo" optionText="ruolo" options={{fullWidth:true}} fullWidth/>
      </ReferenceInput> 
    </Box>
</Box>
</SimpleForm>
  </Create> );




};

