import {comuni_json} from "../components/LBSbirthplace"

const CHECK_CODE_ODD = {
    0: 1,
    1: 0,
    2: 5,
    3: 7,
    4: 9,
    5: 13,
    6: 15,
    7: 17,
    8: 19,
    9: 21,
    A: 1,
    B: 0,
    C: 5,
    D: 7,
    E: 9,
    F: 13,
    G: 15,
    H: 17,
    I: 19,
    J: 21,
    K: 2,
    L: 4,
    M: 18,
    N: 20,
    O: 11,
    P: 3,
    Q: 6,
    R: 8,
    S: 12,
    T: 14,
    U: 16,
    V: 10,
    W: 22,
    X: 25,
    Y: 24,
    Z: 23
  };
  
const CHECK_CODE_EVEN = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
    K: 10,
    L: 11,
    M: 12,
    N: 13,
    O: 14,
    P: 15,
    Q: 16,
    R: 17,
    S: 18,
    T: 19,
    U: 20,
    V: 21,
    W: 22,
    X: 23,
    Y: 24,
    Z: 25
  };
  
const CHECK_CODE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  
export const validateCodiceFiscaleEnhanced=(value)=>{
    var result=false;
    if(value.length!=16){
      result= "Il campo codice fiscale è errato. Controllare la lunghezza";
      return false;
    }
    var cf2=value.substring(11, 15);
    console.log("WER1",cf2)
    if(!comuni_json.some(item => item[0] === cf2))
      return false;    
    let val = 0
    for (let i = 0; i < 15; i = i + 1) {
        const c = value[i]
        val += i % 2 !== 0 ? CHECK_CODE_EVEN[c] : CHECK_CODE_ODD[c]
    }
    val = val % 26;
    console.log("321",CHECK_CODE_CHARS.charAt(val), value.charAt(15));
    if(CHECK_CODE_CHARS.charAt(val)!=value.charAt(15))
      return false;    
    return true;  
};  

export const validateString=(value)=>{
    //console.log("ValidateString Input",value);
    if(value==""||value==null||value==undefined)
    return false;
    var result=false;
    //var re = new RegExp(/^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9*@:!()?+.’`\"'\/|, _-]*$/);
    //var re = new RegExp(/^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9*@:!…()?+.’`\"'\/|, _-]*$/);
    var re = new RegExp(/^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9*@:!“”…&()?+.’`\"'\/|, _-]*$/);
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    //console.log("ValidateString",value, result);
    return result;
};

export const validateCapOrNull=(value)=>{
    if(value==""||value==null||value==undefined)
      return true;
    var result=false;   
    var re = new RegExp("^\\d{5}$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
  return result;
  }

export const validateStringOrNull=(value)=>{
    //console.log("ValidateStringOrNull Input",value);
    if(value==""||value==null||value==undefined)
      return true;
    var result=false;
    //var re = new RegExp(/^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9*@:!()?+.’`\"'\/|, _-]*$/);
    //var re = new RegExp(/^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9*@:!…()?+.’`\"'\/|, _-]*$/);
    var re = new RegExp(/^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9*@:!“”…&()?+.’`\"'\/|, _-]*$/);
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    //console.log("ValidateStringOrNull",value, result);
    return result;
};

export const validateEmail=(value)=>{
    var result=false;    
    let re  = new RegExp(/^[a-zA-Z0-9'+._-]{1,}@[a-zA-Z0-9._-]{1,}\.[a-zA-Z]{1,}$/);
    if (re.test(value)) {
      result=true;
    } else {
      result=false;
    }
  return result;
};

export const validatePhoneNumber=(value)=>{
    if(value==""||value==null||value==undefined)
    return false;
    var result=false;
    var re = new RegExp(/^[0-9- +']*$/);
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
};

export const validateDateOrNull=(value)=>{
    var rr=value;
    if (rr==""||rr==null||rr==undefined)
      rr="1970-01-01";      
    var result=false;
    let re = new RegExp("^\\d{4}[-/]\\d{2}[-/]\\d{2}$");
    if (re.test(rr)) {
      result=true;
    } else {
      //result=false;
      let re2 = new RegExp("^\\d{2}[-/]\\d{2}[-/]\\d{4}$");
      if (re2.test(rr)) {        
        result=true;
      } else {
        result=false;
      }
    }
  return result;
};

export const validateDate=(value)=>{
    if(value==""||value==null||value==undefined)
        return false;
    var result=false;
    let re = new RegExp("^\\d{4}[-/]\\d{2}[-/]\\d{2}$");
    if (re.test(value)) {
      result=true;
    } else {
      let re2 = new RegExp("^\\d{2}[-/]\\d{2}[-/]\\d{4}$");
      if (re2.test(value)) {        
        result=true;
      } else {
        result=false;
    }
  }
  return result;
};

export const validateTesseraMusei=(value)=>{
    if(value==""||value==undefined||value==null)
      return true;
    var result=false;
    var re = new RegExp("^[Aa]{1}[Mm]{1}[Pp]{1}[0-9]{9}$");
    if (re.test(value)) {
        result=true;
    } else {
        result=false;
    }
    return result;
};
  
  
export const validateSex=(value)=>{
    var result=false;
    let re = new RegExp("^\[mfMF]{1}$");
    if (re.test(value)) {
      result=true;
    } else {
      result=false;
    }
  return result;
};

export const validateCodiceFiscale=(value)=>{
    var result=false;
    if(value.length!=16)
      result= false;
    else
      result= true;    
    return result;
};