//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { SimpleList, SelectColumnsButton, Datagrid, TextField, EmailField, UrlField, EditButton, TopToolbar } from "react-admin";
import { Edit, Create, DatagridConfigurable, DateField, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { Fragment,useRef } from 'react';
import { MyEmailField,MyEmptyDateField } from "./MyUrlField";
import { DateInput, Toolbar, SaveButton } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import { CreateButton, FilterButton, ExportButton } from "ra-ui-materialui";
import { Chip }  from '@mui/material';
//import { makeStyles} from '@mui/styles';
import { List, downloadCSV, required } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useTranslate } from 'react-admin';
import React, { useState } from 'react';
//import { useNotify, useRefresh } from 'react-admin';
//import Modal from 'react-modal';
//import { useImport } from 'react-admin-import-csv';
//import DialogTitle from '@mui/material/DialogTitle';
//import Dialog from '@mui/material/Dialog';
//import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
//import DialogActions from '@mui/material/DialogActions';
//import Button from '@mui/material/Button';
//import PropTypes from 'prop-types';
//import { useGetList } from 'react-admin';
//import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Typography } from "@mui/material";
//import {comuni_json} from "../components/LBSbirthplace"
import {validateCodiceFiscaleEnhanced, validateString, validateCapOrNull, validateStringOrNull, validateEmail, validatePhoneNumber, validateDateOrNull, validateDate, validateTesseraMusei, validateSex} from './validations'
//var CodiceFiscale = require('codice-fiscale-js');

var modalOpen;
var additionalInfo;

/*const handleSubmit = async (file) => {
  //console.log("AA222")
  if (!additionalInfo) {    
    console.log("AA223")
    return;
  }

  try {
    
    console.log("AA224")    
  } catch (error) {    
    console.log("AA225")    
  }
}; */


const BambinoBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton   maxResults= {15000} exporter={exporter1}/>      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);



const QuickFilter = ({ label }) => {
  const translate = useTranslate();  
  return <Chip label={translate(label)} />;
};

const BambinoFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,
  <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>
      <SelectInput option="nome" optionText="nome" />
  </ReferenceInput>,  
  <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000} sort={{ field: 'circuito', order: 'ASC' }}>
      <SelectInput option="circuito" optionText="circuito" />
  </ReferenceInput> ,
  <TextInput label="Cognome" source="cognome" />,
  <QuickFilter source="scadenza_abbonamento_musei" label="Senza Abbonamento Musei" defaultValue={"1970-01-01"} />,
  <QuickFilter source="presaDAtto" label="No Newsletter" defaultValue={"Nego"} />
];

const exporter1 = (records, fetchRelatedRecords) => {
      let data;
      let data1;
      let dataForExport;



fetchRelatedRecords(records, 'ente_id', 'enti').then(enti => {
    //console.log("I1:", records);    
    data1 = records.map(record => ({
          ...record,
          ente: enti[record.ente_id].nome,                
    })); 
    //console.log("EXPORT-1",data1);
    //console.log("EXP3",JSON.stringify(data));
    fetchRelatedRecords(data1,'circuito_id','circuiti').then(circuiti =>{
        //console.log("I2",data1);
        data = data1.map(record => ({
        ...record,
        circuito: circuiti[record.circuito_id].circuito,                
    })); 
    //console.log("EXPORT-2",data);
    
    dataForExport = data.map(record => {        
      const { ente_id,circuito_id,gruppo_id,id, ...dataForExport } =record;
      return dataForExport;
  });
     
  //}); 

    jsonExport(dataForExport, {
      headers: ['questionario_id','ente', 'circuito', "timestamp_submit","cognome", "nome", "sesso", "data_nascita", "stato_nascita", 
      "provincia_nascita", "luogo_nascita", "indirizzo_residenza", "citta_residenza", "cap_residenza", "provincia_residenza", "codice_fiscale", 
      "codice_abbonamento_musei",  "scadenza_abbonamento_musei", "confermaPresaDAtto", "presaDAtto1","presaDAtto", "cognome_genitore",
      "nome_genitore", "email_genitore", "telefono_genitore"],
   

  }, (err, csv) => {
      downloadCSV(csv, 'registrazioni');
  });
  })
    
});
};
  

const BambinoActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      <CreateButton basePath={basePath} />
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        maxResults= {15000}
        exporter={exporter1}
      />        
    </TopToolbar>
  );
};


export const BambinoAdminList = (props) => (
  <List {...props} sort={{ field: 'timestamp_submit', order: 'DESC' }} filters={BambinoFilters} perPage={50} actions={<BambinoActions />}>
  {/*<List exporter={exporter} filters={BambinoFilters}> */}
    {/*<Datagrid rowClick="edit">*/}
    <DatagridConfigurable  bulkActionButtons={<BambinoBulkActionButtons />}> 
    {/*<TextField source="id" /> */}
    {/*<TextField source="questionario_id" />*/}
    <ReferenceField source="questionario_id" reference="questionari" label="Modulo">
        <TextField source="link" />
    </ReferenceField>
    <ReferenceField source="ente_id" reference="enti" label="Ente">
        <TextField source="nome" />
    </ReferenceField>
    <ReferenceField source="circuito_id" reference="circuiti" label="Circuito">
        <TextField source="circuito" />
    </ReferenceField>
    <DateField source="timestamp_submit" label="Data completamento" locales="it-IT" showTime={true}/>
    <TextField source="cognome" />
    <TextField source="nome" />
    <TextField source="sesso" />
    <DateField source="data_nascita" label="Data di nascita" locales="it-IT" showTime={false}/>
    <TextField source="stato_nascita" label="Stato di nascita"/>
    <TextField source="provincia_nascita" label="Provincia di nascita"/>
    <TextField source="luogo_nascita" label="Luogo di nascita"/>
    <TextField source="indirizzo_residenza" label="Indirizzo di residenza"/>
    <TextField source="citta_residenza" label="Città di residenza"/>
    <TextField source="cap_residenza" label="CAP di residenza"/>
    <TextField source="provincia_residenza" label="Provincia di residenza"/>
    <TextField source="codice_fiscale" label="Codice fiscale"/>
    <TextField source="codice_abbonamento_musei" label="Codice abbonamento musei"/>
    <MyEmptyDateField source="scadenza_abbonamento_musei" label="Scadenza abbonamento musei" locales="it-IT" showTime={false}/>    
    <TextField source="confermaPresaDAtto" label="Presa visione"/>
    <TextField source="presaDAtto1" label="Iscrizione estate insieme"/>
    <TextField source="presaDAtto" label="Ricezione newsletter"/>
    <TextField source="cognome_genitore" label="Cognome genitore"/>
    <TextField source="nome_genitore" label="Nome genitore"/>
    <MyEmailField source="email_genitore" label="E-mail genitore"/>
    <TextField source="telefono_genitore" label="Telefono genitore"/>
      <EditButton/>
    </DatagridConfigurable>
  </List>
);

const BambinoEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

export const BambinoAdminEdit = () => {
  return(  
      <Edit title={<username />} mutationMode="pessimistic"> 
        <SimpleForm sx={{ maxWidth: '100%' }} toolbar={<BambinoEditToolbar />} validate={validateBambinoFields}>
        <Typography variant="h6" gutterBottom>
                    Dati registrazione
          </Typography>               
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} filter={{tipologia_id: 2}} sort={{ field: 'nome', order: 'ASC' }}>
                  <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome" options={{fullWidth:true}} fullWidth/>
                </ReferenceInput>
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000} sort={{ field: 'circuito', order: 'ASC' }}>
                  <SelectInput sx={{textAlign: "left"}} option="circuito" optionText="circuito" options={{fullWidth:true}} fullWidth/>
                </ReferenceInput> 
              </Box>
          </Box>
          
          <Typography variant="h6" gutterBottom>
                    Dati anagrafica bambino
          </Typography>   
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="cognome" fullWidth/>
                </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="nome" fullWidth/>
              </Box>
          </Box>  
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput sx={{textAlign: "left"}}  source="sesso"  options={{fullWidth:true}} fullWidth
            choices={[{ id: 'M', name: 'M' },{ id: 'F', name: 'F' } ]}/>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <DateInput source="data_nascita" label="Data di nascita" locales="it-IT" fullWidth/>
            </Box>
          </Box>

          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="stato_nascita" label="Stato di nascita" fullWidth/>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="provincia_nascita" label="Provincia di nascita" fullWidth/>
            </Box>
          </Box>

          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="luogo_nascita" label="Luogo di nascita" fullWidth/>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="codice_fiscale" label="Codice fiscale" fullWidth/>
            </Box>
          </Box>


          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="indirizzo_residenza" label="Indirizzo di residenza" fullWidth/>    
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="citta_residenza" label="Città di residenza" fullWidth/>
            </Box>
          </Box>

          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="cap_residenza" label="CAP di residenza" fullWidth/>    
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="provincia_residenza" label="Provincia di residenza" fullWidth/>
            </Box>
          </Box>
        
        <Typography variant="h6" gutterBottom>
                    Dati genitore
          </Typography>  
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="cognome_genitore" label="Cognome genitore" fullWidth/>
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="nome_genitore" label="Nome genitore" fullWidth/>
              </Box>
          </Box>

          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="email_genitore" label="E-mail genitore" fullWidth/>
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="telefono_genitore" label="Telefono genitore"fullWidth/>
              </Box>
          </Box>
          <Typography variant="h6" gutterBottom>
                    Dati tessera abbonamento musei
          </Typography>  
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="codice_abbonamento_musei" label="Codice abbonamento musei" fullWidth/>    
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <DateInput source="scadenza_abbonamento_musei" label="Scadenza abbonamento musei" locales="it-IT" fullWidth/>
            </Box>
          </Box>    
        </SimpleForm>
      </Edit>
    )
};



const transform2 = (data) => {
  const sanitizedData = {};
  for (const key in data) {   
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["timestamp_submit"]=new Date().toISOString().slice(0, 19).replace('T', ' ');  
  sanitizedData["questionario_id"]=777;
  sanitizedData["confermaPresaDAtto"]="Coonfermo la presa visione";
  sanitizedData["presaDAtto1"]= "Acconsento";
  sanitizedData["presaDAtto"]= "Acconsento";
  sanitizedData["scadenza_abbonamento_musei"]= "1970-01-01";
  return sanitizedData; 
};

/*
const validateTesseraMusei=(value)=>{
  if(value==""||value==undefined||value==null)
    return true;
  var result=false;
  var re = new RegExp("^[Aa]{1}[Mm]{1}[Pp]{1}[0-9]{9}$");
  if (re.test(value)) {
      result=true;
  } else {
      result=false;
  }
  return result;
}


const validateSex=(value)=>{
  var result=false;
  let re = new RegExp("^\[mfMF]{1}$");
  if (re.test(value)) {
    result=true;
  } else {
    result=false;
  }
return result;
}

const validateString=(value)=>{
  var result=false;
  var re = new RegExp("^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9-' ]*$");
  if (re.test(value)) {
      result=true;
  } else {
      result=false;
  }
  return result;
}


const validateCapOrNull=(value)=>{
  if(value==""||value==null||value==undefined)
    return true;
  var result=false;   
  var re = new RegExp("^\\d{5}$");
  if (re.test(value)) {
      result=true;
  } else {
      result=false;
  }
return result;
}

const validateStringOrNull=(value)=>{
  if(value==""||value==null||value==undefined)
    return true;
  var result=false;
  var re = new RegExp("^[ÀÁÈÉÌÒÙèéòàùìa-zA-Z0-9-' ]*$");
  if (re.test(value)) {
      result=true;
  } else {
      result=false;
  }
  return result;
}

const validateDate=(value)=>{
  var result=false;
  let re = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
  if (re.test(value)) {
    result=true;
  } else {
    let re2 = new RegExp("^\\d{2}-\\d{2}-\\d{4}$");
    if (re2.test(value)) {        
      result=true;
    } else {
      result=false;
  }
}
return result;
}

const validateDateOrNull=(value)=>{
  var rr=value;
  if (rr==""||rr==null||rr==undefined)
    rr="1970-01-01";      
  var result=false;
  let re = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
  if (re.test(rr)) {
    result=true;
  } else {
    result=false;
  }
return result;
}

const validateEmail=(value)=>{
  var result=false;
  //let re = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
  //let re =new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/);
  //let re = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.)+[^<>()\.,;:\s@\"]{2,})$/);
  let re  = new RegExp(/^[a-zA-Z0-9'+._-]{1,}@[a-zA-Z0-9._-]{1,}\.[a-zA-Z]{1,}$/);
  if (re.test(value)) {
    result=true;
  } else {
    result=false;
  }
return result;
}

const validatePhoneNumber=(value)=>{
  var result=false;
  var re = new RegExp("^[0-9- +']*$");
  if (re.test(value)) {
      result=true;
  } else {
      result=false;
  }
  return result;
}
*/


const validateBambinoFields = (values) => {
  const errors = {};
  if(values.ente_id ==null || values.ente_id ==""||values.ente_id == undefined)
    errors.ente_id="Il campo Ente non può essere vuoto";
  if(values.circuito_id ==null || values.circuito_id ==""||values.circuito_id == undefined)
    errors.circuito_id="Il campo Circuito non può essere vuoto";
  if(!validateString(values.cognome))
    errors.cognome="Il campo cognome contiene dei caratteri non supportati";
  if(!validateString(values.nome))
    errors.nome="Il campo nome contiene dei caratteri non supportati";
  if(!validateString(values.stato_nascita))
    errors.stato_nascita="Il campo stato di nascita contiene dei caratteri non supportati";
  if(!validateStringOrNull(values.provincia_nascita))
    errors.provincia_nascita="Il campo provincia di nascita contiene dei caratteri non supportati";  
  if(!validateStringOrNull(values.luogo_nascita))
    errors.luogo_nascita="Il campo luogo di nascita contiene dei caratteri non supportati";
  if(!validateString(values.indirizzo_residenza))
    errors.indirizzo_residenza="Il campo indirizzo di residenza contiene dei caratteri non supportati";
  if(!validateString(values.citta_residenza))
    errors.citta_residenza="Il campo città di residenza contiene dei caratteri non supportati";
  if(!validateStringOrNull(values.provincia_residenza))
    errors.provincia_residenza="Il campo provincia di residenza contiene dei caratteri non supportati";      
  if(!validateString(values.cognome_genitore))
    errors.cognome_genitore="Il campo cognome genitore contiene dei caratteri non supportati";
  if(!validateString(values.nome_genitore))
    errors.nome_genitore="Il campo nome genitore contiene dei caratteri non supportati";
  if(!validateEmail(values.email_genitore))
    errors.email_genitore="Il campo e-mail è in un formato non corretto";  
  if(!validatePhoneNumber(values.telefono_genitore))
    errors.telefono_genitore="Il campo numero di telefono contiene caratteri non supportati";  
  if(!validateTesseraMusei(values.codice_abbonamento_musei))
    errors.codice_abbonamento_musei="Il campo tessera musei è in un formato non corretto";  
  if(!validateDateOrNull(values.scadenza_abbonamento_musei))
    errors.scadenza_abbonamento_musei="Il campo data scadenza abbonamento musei è in un formato non corretto";  
  if(!validateSex(values.sesso))
    errors.sesso="Il campo sesso contiene dei caratteri non supportati";    
  if(!validateDate(values.data_nascita))
    errors.data_nascita="Il campo data di nascita è in un formato non corretto";    
  if(!validateCapOrNull(values.cap_residenza))
    errors.cap_residenza="Il campo CAP è in un formato non corretto";          
  var data=values.data_nascita;
  var cf=values.codice_fiscale;
  if(cf!=undefined && cf!=null){
    if(values.codice_fiscale.length!=16)
      errors.codice_fiscale="Il campo codice fiscale deve essere lungo 16 caratteri";      
  }
  else
      errors.codice_fiscale="Il campo codice fiscale è obbligatorio";      
return errors
};


export const BambinoAdminCreate =  ({ onSuccess, ...props }) => {    
  return(

    <Create transform={transform2}  {...props} >   
    {/*<SimpleForm onSubmit={handleCreate} validate={validateCFFields} sx={{ maxWidth: '100%' }}>   */}
    <SimpleForm validate={validateBambinoFields} sx={{ maxWidth: '100%' }}> 
    <Typography variant="h6" gutterBottom>
        Dati registrazione
    </Typography>       
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} filter={{tipologia_id: 2}} >     
            <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"  options={{fullWidth:true}} fullWidth validate={required()}/>
        </ReferenceInput>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000}>
                <SelectInput  sx={{textAlign: "left"}} option="circuito" optionText="circuito" options={{fullWidth:true}} fullWidth validate={required()}/>
        </ReferenceInput>    
      </Box>
    </Box>
    <Typography variant="h6" gutterBottom>
        Dati anagrafica bambino
    </Typography>   
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
        <TextInput source="cognome" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="nome" fullWidth validate={required()}/>
      </Box>
    </Box>  

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <SelectInput sx={{textAlign: "left"}}  source="sesso"  options={{fullWidth:true}} fullWidth
        choices={[{ id: 'M', name: 'M' },{ id: 'F', name: 'F' } ]} validate={required()}/>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <DateInput source="data_nascita" label="Data di nascita" locales="it-IT" fullWidth validate={required()}/>    
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="stato_nascita" label="Stato di nascita" fullWidth validate={required()}/>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="provincia_nascita" label="Provincia di nascita" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="luogo_nascita" label="Luogo di nascita" fullWidth validate={required()}/>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="codice_fiscale" label="Codice fiscale" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="indirizzo_residenza" label="Indirizzo di residenza" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="citta_residenza" label="Città di residenza" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="cap_residenza" label="CAP di residenza" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="provincia_residenza" label="Provincia di residenza" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Typography variant="h6" gutterBottom>
        Dati genitore
    </Typography>  
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="cognome_genitore" label="Cognome genitore" fullWidth validate={required()}/>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="nome_genitore" label="Nome genitore" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="email_genitore" label="E-mail genitore" fullWidth validate={required()}/>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="telefono_genitore" label="Telefono genitore"fullWidth validate={required()}/>
      </Box>
    </Box>

    <Typography variant="h6" gutterBottom>
        Dati tessera abbonamento musei
    </Typography>  
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="codice_abbonamento_musei" label="Codice abbonamento musei" fullWidth/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <DateInput source="scadenza_abbonamento_musei" label="Scadenza abbonamento musei" locales="it-IT" fullWidth/>
      </Box>
    </Box>    

    </SimpleForm>
    </Create> 
  );
}
