//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { SimpleList, SelectColumnsButton, Datagrid, TextField, EmailField, UrlField, EditButton, TopToolbar } from "react-admin";
import { Edit, Create, DatagridConfigurable, DateField, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { Fragment,useRef } from 'react';
import { MyEmailField,MyEmptyDateField } from "./MyUrlField";
import { DateInput } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import { CreateButton, FilterButton, ExportButton } from "ra-ui-materialui";
import { Chip }  from '@mui/material';
//import { makeStyles} from '@mui/styles';
import { List, downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useTranslate } from 'react-admin';
import React, { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';

import { useImport } from 'react-admin-import-csv';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useGetList } from 'react-admin';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Typography } from "@mui/material";


var additionalInfo;


const BambinoBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton   maxResults= {15000} exporter={exporter1}/>      
   {/**   <BulkDeleteButton mutationMode="pessimistic"/>  */}
  </Fragment>
);



const QuickFilter = ({ label }) => {
  const translate = useTranslate();  
  return <Chip label={translate(label)} />;
};

const BambinoFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,
  <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>
      <SelectInput option="nome" optionText="nome" />
  </ReferenceInput>,  
  <ReferenceInput source="circuito_id" reference="circuiti" label="Circuito" perPage={1000} sort={{ field: 'circuito', order: 'ASC' }}>
      <SelectInput option="circuito" optionText="circuito" />
  </ReferenceInput> ,
  <TextInput label="Cognome" source="cognome" />,
  <QuickFilter source="scadenza_abbonamento_musei" label="Senza Abbonamento Musei" defaultValue={"1970-01-01"} />,
  <QuickFilter source="presaDAtto" label="No Newsletter" defaultValue={"Nego"} />
];

const exporter1 = (records, fetchRelatedRecords) => {
      let data;
      let data1;
      let dataForExport;



fetchRelatedRecords(records, 'ente_id', 'enti').then(enti => {
    //console.log("I1:", records);    
    data1 = records.map(record => ({
          ...record,
          ente: enti[record.ente_id].nome,                
    })); 
    //console.log("EXPORT-1",data1);
    //console.log("EXP3",JSON.stringify(data));
    fetchRelatedRecords(data1,'circuito_id','circuiti').then(circuiti =>{
        //console.log("I2",data1);
        data = data1.map(record => ({
        ...record,
        circuito: circuiti[record.circuito_id].circuito,                
    })); 
    //console.log("EXPORT-2",data);
    
    dataForExport = data.map(record => {        
      const { ente_id,circuito_id,gruppo_id,id, ...dataForExport } =record;
      return dataForExport;
  });
     
  //}); 

    jsonExport(dataForExport, {
      headers: ['questionario_id','ente', 'circuito', "timestamp_submit","cognome", "nome", "sesso", "data_nascita", "stato_nascita", 
      "provincia_nascita", "luogo_nascita", "indirizzo_residenza", "citta_residenza", "cap_residenza", "provincia_residenza", "codice_fiscale", 
      "codice_abbonamento_musei",  "scadenza_abbonamento_musei", "confermaPresaDAtto", "presaDAtto1","presaDAtto", "cognome_genitore",
      "nome_genitore", "email_genitore", "telefono_genitore"],
   

  }, (err, csv) => {
      downloadCSV(csv, 'registrazioni');
  });
  })
    
});
};
  

const BambinoActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      {/*<CreateButton basePath={basePath} /> */}
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        maxResults= {15000}
        exporter={exporter1}
      />    
    </TopToolbar>
  );
};


export const BambinoNoAdminList = (props) => (
  <List {...props} sort={{ field: 'timestamp_submit', order: 'DESC' }} filters={BambinoFilters} perPage={50} actions={<BambinoActions />}>
  {/*<List exporter={exporter} filters={BambinoFilters}> */}
    {/*<Datagrid rowClick="edit">*/}
    <DatagridConfigurable  bulkActionButtons={<BambinoBulkActionButtons />}> 
    {/*<TextField source="id" /> */}
    {/*<TextField source="questionario_id" />*/}
    <ReferenceField source="questionario_id" reference="questionari" label="Modulo">
        <TextField source="link" />
    </ReferenceField>
    <ReferenceField source="ente_id" reference="enti" label="Ente">
        <TextField source="nome" />
    </ReferenceField>
    <ReferenceField source="circuito_id" reference="circuiti" label="Circuito">
        <TextField source="circuito" />
    </ReferenceField>
    <DateField source="timestamp_submit" label="Data completamento" locales="it-IT" showTime={true}/>
    <TextField source="cognome" />
    <TextField source="nome" />
    <TextField source="sesso" />
    <DateField source="data_nascita" label="Data di nascita" locales="it-IT" showTime={false}/>
    <TextField source="stato_nascita" label="Stato di nascita"/>
    <TextField source="provincia_nascita" label="Provincia di nascita"/>
    <TextField source="luogo_nascita" label="Luogo di nascita"/>
    <TextField source="indirizzo_residenza" label="Indirizzo di residenza"/>
    <TextField source="citta_residenza" label="Città di residenza"/>
    <TextField source="cap_residenza" label="CAP di residenza"/>
    <TextField source="provincia_residenza" label="Provincia di residenza"/>
    <TextField source="codice_fiscale" label="Codice fiscale"/>
    <TextField source="codice_abbonamento_musei" label="Codice abbonamento musei"/>
    <MyEmptyDateField source="scadenza_abbonamento_musei" label="Scadenza abbonamento musei" locales="it-IT" showTime={false}/>    
    <TextField source="confermaPresaDAtto" label="Presa visione"/>
    <TextField source="presaDAtto1" label="Iscrizione estate insieme"/>
    <TextField source="presaDAtto" label="Ricezione newsletter"/>
    <TextField source="cognome_genitore" label="Cognome genitore"/>
    <TextField source="nome_genitore" label="Nome genitore"/>
    <MyEmailField source="email_genitore" label="E-mail genitore"/>
    <TextField source="telefono_genitore" label="Telefono genitore"/>      
    </DatagridConfigurable>
  </List>
);
